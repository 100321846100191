import React, { Component } from "react";
import moment from "moment";
import SideNav from "../template/SideNav";
import TopHeader from "../template/TopHeader";
import { connect } from "react-redux";
import {
  bookingActions,
  hairdresserActions,
  paymentActions,
  userActions,
} from "../../_actions";

import siteSetting from "../../config/env";
import dateFormat from "dateformat";
// import Checkout from "../hairdresserBooking/Checkout";
import Checkout from "./Checkout";
import { bookingService, paymentService } from "../../_services";
import { loadStripeTerminal } from "@stripe/terminal-js";
import CheckoutProduct from "./CheckoutProduct";
import CheckoutService from "./CheckoutService";
import { productAction } from "../../_actions/product.action";
import BookingNavigation from "./BookingNavigation";
import toast from "react-toast-notification";



var _ = require("lodash");

let userIdHolder = [];
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getslotTime(time) {
  let hr = parseInt(time / 60);
  let min = time - hr * 60;
  return (
    (hr <= 9 ? "0" + hr : hr) +
    ":" +
    (min == 0 ? "00" : min <= 9 ? "0" + min : min) +
    ":00"
  );
}

var terminal;

// loadStripeTerminal().then(function (StripeTerminal) {
//   terminal = StripeTerminal.create({
//     onFetchConnectionToken: fetchConnectionToken,
//     onUnexpectedReaderDisconnect: unexpectedDisconnect,
//   });
// });

function fetchConnectionToken() {
  let self = this;
  return paymentService.fetchConnectionToken().then(function (res) {
    console.log("fetchConnectionToken res", res.data);
    return res.data.responseData.result.secret;
  });
}

function unexpectedDisconnect() {
  console.timeLog("disconnect@@@@@@@@");
}

class InstantCheckout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bookingDate: new Date(),
      selectSlot: "",
      showOptionsModal: false,
      showTipModal: false,
      latitude: siteSetting.default_latitude,
      longitude: siteSetting.default_longitude,
      hairdresserName: "",
      selectedService: [],
      finalBookingSlots: [],
      includeTimes: [],
      slotInfo: {},
      totalAmount: 0,
      prevPrice: 0,
      servicePrice: 0,
      totalTime: 0,
      hairdresserId: "",
      priceError:'',
      selectedClientId: "",
      selectedClient: {},
      isSaveBooking: 0,
      bookingSlots: [],
      paymentType: "",
      isBookingAllowed: false,
      searchKey: "",
      bookingType: "past", //upcoming
      pendingAmount: 0,
      transaction: [],
      paymentAmount: 0,
      totalPaymentAmount: 0,
      isCheckout: 1,
      userId: "",
      isInvoice: 0,
      currencySymbol: "£",
      bookingId: "",
      isServiceEdit: false,
      transactions: [],
      invoiceNumber: 0,
      selectedPaymentType: null,
      selectedAmount: "",
      oldTransaction: [],
      serviceHardresserList: [],
      checkOutAmountType: true,
      selectedFixedAmount: 0,
      isPaymentComplete: 0,
      priceErrorStatus:true,
      isMobileEnableState: true,
      locationId: "",
      selectedCardReader: {},
      paymentIntents: {},
      isCardReaderConnected: false,
      isShowConnectionLoader: false,
      registrationCode: "",
      showDiscoversModal: false,
      isCheckoutPayment: false,
      payoutError: "",
      cardReaderConnectError: "",
      label: "",
      tipAmount: 0,
      showService: false,
      selectedServices: [],
      allServices: [],
      fromServices: [],
      endTimeSlot: new Date(),
      hairDresserName: "",
      selectedTime: "",
      newStartTime: "",
      productList: [],
      serviceList: [],
      checkoutSelectedItems:[],
      staffList:[],
      selectedStaff:null,
      selectedClientRecord:null,
      selectedTotalAmount: 0,
      isTrStart:false
    };

    this.editCheckoutBooking = this.editCheckoutBooking.bind(this);
    this.resetBooking = this.resetBooking.bind(this);
    this.checkoutBooking = this.checkoutBooking.bind(this);
    this.onChanged = this.onChanged.bind(this);
    this.updateBooking = this.updateBooking.bind(this);
    this.staffMemberHandler = this.setSelectedProduct.bind(this);
    this.setSelectedClient= this.setSelectedClient.bind(this);
    this.calculateCheckoutItem = this.calculateCheckoutItem.bind(this);
    this.completeSale = this.completeSale.bind(this);
    // this.completeSaleForCardReader = this.completeSaleForCardReader.bind(this);
    this.checkout = this.checkout.bind(this);
    this.createBooking = this.createBooking.bind(this);
    this.updatedCheckOut = this.updatedCheckOut.bind(this);
    this.changeItemPrice = this.changeItemPrice.bind(this);
    this.updateNewEditAmount = this.updateNewEditAmount.bind(this);
  }

// end get staff
  handleOptionModal = () =>
    this.setState({ showOptionsModal: !this.state.showOptionsModal });

  handleTipModal = () => {
    this.setState({
      showOptionsModal: false,
      showTipModal: !this.state.showTipModal,
    });
  };

  handleDiscoverModal = () => {
    this.setState({
      showDiscoversModal: true,
    });
  };

  handleClose = () => {
    this.setState({
      showDiscoversModal: false,
    });
  };


  setSelectedCheckoutData(){
    let self = this;
    const selectedCheckOutArr = self.props?.selectedCheckoutList?.length>0?self.props?.selectedCheckoutList:[];
   
    self.setState({
      checkoutSelectedItems: selectedCheckOutArr,
      selectedStaff:selectedCheckOutArr[0]?.selectedStaff!==undefined?selectedCheckOutArr[0]?.selectedStaff:null,
      selectedClient:selectedCheckOutArr[0]?.selectedClient!==undefined?selectedCheckOutArr[0]?.selectedClient:null,
    })
    this.setSelectedProduct();
    this.setSelectedClient();
    this.setSelectedService();
  }

  setSelectedProduct(){
    let self = this;
    let dispatch = self.props.dispatch;
    let selectedProductData = self.props.selectCheckoutItem;


    if(selectedProductData?._id !== undefined){
     let updatedList = self.props?.selectedCheckoutList.length>0?self.props?.selectedCheckoutList.map(item=>{
        if(item?.id===selectedProductData.indexId){
          item = {...item,...selectedProductData};
        }
        return item
      }):[];

      dispatch(productAction?.selectedCheckoutList(updatedList));
      self.setState({
        checkoutSelectedItems: updatedList,
      },()=>{
        self.calculateCheckoutItem();
      })
    }
  }

  setSelectedClient(){
    let self = this;
    let selectedClientData = self.props.selectedClient;
    if(selectedClientData?._id !== undefined){
      self.setState({
        selectedClient: selectedClientData
      })
    }else{
      self.props.dispatch(productAction.selectedClient(siteSetting.noClientDAta));
      self.setState({
        selectedClient: siteSetting.noClientDAta,
      })
    }
  }



  setSelectedService(){
    let self = this;
    let dispatch = self.props.dispatch;
    let selectedServiceData = self.props?.selectedService;
    let totalAmount=0;
    if(selectedServiceData?._id !== undefined){
     let updatedList = self.props?.selectedCheckoutList.length>0?self.props?.selectedCheckoutList.map(item=>{
      totalAmount += isNaN(item.servicePrice)?selectedServiceData?.servicePrice:item.servicePrice;  
      if(item?.id===selectedServiceData.indexId){
          item.selectedService = selectedServiceData;
        }
        return item
      }):[];
      dispatch(hairdresserActions.selectedService({}));
      dispatch(productAction?.selectedCheckoutList(updatedList));
      self.setState({
        selectedTotalAmount:totalAmount,
        totalAmount:totalAmount,
        selectedCheckoutList: updatedList,
      },function (){
        self.calculateCheckoutItem();
      })
    }
  }

  componentDidMount() {
    var self = this;
    let pageStatus = 0;
    const { state } = this.props.location;
    console.log('check navigation tab 1',state);
    if (state) {
    const { pageLoadingStatus } = state;
        pageStatus = pageLoadingStatus!==undefined?pageLoadingStatus:0;

      if(pageLoadingStatus===1){
        // all redux clear here
   
        self.props.dispatch(hairdresserActions.selectedService({}));
        self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
        self.props.dispatch(bookingActions?.hairdresserNewBookingClient({})
        );
        self.props.dispatch(bookingActions?.selectHairdresserBookingDate({}));
        self.props.dispatch(bookingActions?.clientBookingSlots({}))
        self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({}));
        self.props.dispatch(productAction.selectedClient({}));
        self.props.dispatch(productAction?.selectCheckoutItem({}));
        self.props.dispatch(productAction?.selectedCheckoutList([]));

        let serviceData = [];
        self.props.dispatch(productAction?.selectedCheckoutList(serviceData));
        self.setState({
          checkoutSelectedItems:[],
        });
      } 
    }

    const body = document.getElementsByClassName("body_container")[0];
    body.classList.remove("cm_bg_white");
    body.classList.add("body_bg_lightGray");

   
    let params = {
      defaultRedirectTo: localStorage.getItem("defaultRedirectTo"),
    };
    localStorage.setItem("isShow", "true");
   
    let params1 = {
      searchKey: this.state.searchKey,
      hairdresserId: localStorage.getItem("userId"),
      page: 1,
      listType: 1,
    };
    if(self.props.staffRota?.rota != undefined) {
      let staffData = self.props.staffRota?.rota != undefined ? self.props.staffRota?.rota : [];
      console.log("staffData",staffData);
      const staffOptionList = staffData?.map((staff) => {
        return {value: staff?._id, label: staff?.name};
      });

      const serviceHardresserList = staffData?.map((staff) => {
        return {id: staff?._id, name: staff?.name};
      });


      self.setState({
        staffList: staffOptionList,
        serviceHardresserList: serviceHardresserList,
      });
    }
    self.calculateCheckoutItem();
    this.setSelectedCheckoutData();
    if (localStorage.getItem("accessToken")) {
      self.props
        .dispatch(userActions.profile(params))
        .then(function (response) {
          if (response.data.data) {
            self.setState({
              locationId: response?.data?.data?.userInfo?.hairdresser
                ?.stripeLocationId
                ? response?.data?.data?.userInfo?.hairdresser?.stripeLocationId
                : "",
            });
            self.cardReaderList(response.data.data.userInfo);
          }
        });

      self.cardReaderList();
      self.props.dispatch(hairdresserActions.hairdresserStaffRota(params1))
          .then(function (response) {
            let staffData = response?.data?.data?.rota!=undefined?response?.data?.data?.rota:[];
           const staffOptionList =  staffData?.map(staff=>{
              return {value:staff?._id,label:staff?.name}
            })
            self.setState({
              staffList:staffOptionList
            })
          })

    } else {
      this.props.history.push("/");
    }

    let { hairdresser } = this.props;

    if (
      this.props?.hairdresserBooking?.bookings &&
      this.props?.hairdresserBooking?.bookings?.length > 0
    ) {
      let prev = this.props?.hairdresserBooking?.bookings[0]?.servicesBooked;

      let servicesData = [];
      let hairdresserServices = hairdresser?.hairdresserServices;
      for (let i = 0; i < hairdresserServices?.length; i++) {
        let category = hairdresserServices[i].hairdresserService;
        for (let j = 0; j < category.length; j++) {
          servicesData.push(category[j]);
        }
      }

      let temp = [];

      const filterData = servicesData.filter(
        (serviceData) =>
          !prev.some(
            (prevService) => prevService.serviceId === serviceData.serviceId
          )
      );

      for (let i = 0; i < prev.length; i++) {
        temp.push(filterData);
      }

      let staffMember =
        this?.props?.hairdresserBooking?.bookings[0]?.hairdresser.name;

      let path =
        this.props.history.location?.state?.pname === "ListCheckout"
          ? this.props.history.location?.state?.pname
          : "";
      let serviceNumber = this.props.history.location?.state?.serviceNumber;
      if (path === "ListCheckout" && serviceNumber <= prev.length) {
        prev[serviceNumber] = this.props.history.location?.state?.product;
        self.setState({
          selectSlot: this.props.history.location?.state?.startTime,
          totalTime: this.props.history.location?.state?.serviceDuration,
          totalAmount: this.props.history.location?.state?.previousPrice,
          pendingAmount: this.props.history.location?.state?.previousPrice,
        });
      } else if (serviceNumber > prev.length) {
        prev.push(this.props.history.location.state.product);
      }

      this.setState({
        allServices: servicesData,
        fromServices: temp,
        selectedServices: prev,
        hairDresserName: staffMember,
        newStartTime: this?.state?.selectSlot,
      });
    }
  }



  calculateCheckoutItem() {
    let self = this;
    let selectedCheckoutList = this.props?.selectedCheckoutList?.length>0?this.props?.selectedCheckoutList:[];
    let totalServicePrice = 0;
    let totalRetailPrice = 0;
    let selectedProduct = [];
    let _selectedServices = [];

    for (const item of selectedCheckoutList) {
      if (item.type === "product") {
        selectedProduct.push(item);
        totalRetailPrice += item?.retailPrice !== undefined ? parseFloat(item?.retailPrice) : 0;
      } else if (item?.type === "service") {
        _selectedServices.push(item);
        totalServicePrice += item?.selectedService?.servicePrice !== undefined ? parseFloat(item?.selectedService?.servicePrice): 0;
      }
    }

    const checkOutTotalAmount = totalServicePrice + totalRetailPrice;

    const updatedData = {
      selectedService: _selectedServices,
      productList: selectedProduct,
      totalAmount: checkOutTotalAmount,
      pendingAmount: checkOutTotalAmount,
      paymentAmount: checkOutTotalAmount,
      selectedTotalAmount: checkOutTotalAmount,
    };

    self.setState(updatedData);
    return updatedData;
  }


  serviceHairdresserList = (salonId, selectedHairdresserService) => {
    let self = this;
    let hairdresserListParam = {
      salonId: salonId,
      selectedService: selectedHairdresserService,
    };
    self.props
      .dispatch(hairdresserActions.serviceHairdresserList(hairdresserListParam))
      .then(function (response) {
        if (response.data.errorCode) {
          self.setState({
            error: response.data.error.messages,
          });
        } else {
          self.setState({
            serviceHardresserList: response?.data?.data,
          });
        }
      });
  };




  selectedHairdresser(hairdresserId, hairdresserName, type, hairdresser) {
    // console.log("====",hairdresserId, hairdresserName,type,hairdresser)
    this.setState({ isSelected: true });
    this.setState({
      startTimeError: "",
      serviceError: "",
      hairdresserId,
      hairdresserName,
    });
    // console.log("salonId",salonId);
    //this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
    let params = {};
    let self = this;
    params.hairdresserId = hairdresserId;
    params.latitude = this.state.latitude;
    params.longitude = this.state.longitude;
    params.history = this.props.history;


    self.props
      .dispatch(hairdresserActions.getHairdresserInfo(params))
      .then(function (response) {
        if (response.data.errorCode) {
          self.setState({
            error: response.data.error.messages,
          });
        } else {
          self.setState(
            {
              currencySymbol:
                hairdresser.hairdresser.currencySymbol &&
                hairdresser.hairdresser.currencySymbol != "" &&
                hairdresser.hairdresser.currencySymbol != "undefined"
                  ? hairdresser.hairdresser.currencySymbol
                  : "£",
            },
            function () {
              self.bookingSlotCreate(0);
            }
          );
        }
      });
  }



  resetBooking() {
    window.location.reload();
  }

  changeClient() {
    this.setState({
      selectedClientId: "",
      selectedClient: {},
      isSaveBooking: 0,
      bookingSlots: [],
      paymentType: "", //start working from here
      searchKey: "",
    });
  }

  handleEvent(e, d) {
    if (e === "eventStartTime") {
      let m = moment(d);
      let minutes = m.hour() * 60 + m.minute();
      this.setState({ eventTime: d, eventStartTime: minutes });
    }
    if (e === "eventEndTime") {
      let m = moment(d);
      let minutes = m.hour() * 60 + m.minute();
      this.setState({ endTime: d, eventEndTime: minutes });
    }
    if (typeof e === "object" && e.target.id === "eventTitle") {
      this.setState({ eventTitle: e.target.value });
    }
    if (typeof e === "object" && e.target.id === "isBookingAllowed") {
      this.setState({ isBookingAllowed: !this.state.isBookingAllowed });
    }
  }

checkoutBooking() {
    this.setState({
      isCheckout: 1,
    });
  }


  onChanged(e) {
    this.setState({ [e.target.name]: parseFloat(e.target.value) });
    if (e.target.name === "registrationCode") {
      this.setState({ [e.target.name]: e.target.value });
    } else if (e.target.name === "label") {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: parseFloat(e.target.value) });
    }
    if (e.target.name === "pendingAmount") {
      let totalPaymentAmount =
        parseFloat(this.state.totalPaymentAmount) + parseFloat(e.target.value);
      let pendingAmount =
        parseFloat(this.state.totalAmount) - totalPaymentAmount;
      if (parseFloat(e.target.value) > pendingAmount) {
        this.setState({
          paymentAmountError: "Invalid Amount",
        });
      } else {
        this.setState({
          paymentAmountError: "",
        });
      }
    }
  }

  updateBooking(reload = 1) {
    let params = {};
    params.servicesToBook = _.map(this.state.selectedService, function (o) {
      return o.serviceId;
    });
    params.userId = this.state.hairdresserId;
    params.transaction = this.state.transaction;
    //params.totalAmount = parseFloat(this.state.totalAmount).toFixed(2);
    params.bookingID = this.state.bookingId;
    bookingService.updateBooking(params).then(function (response) {
      if (reload) {
        window.location.reload();
      }
    });
  }

  createBooking=(callback='')=>{
    let self=this;
    let filteredProduct = [];
    let filteredService = [];
    let transaction = this.state.transaction;

    // _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
    //   if(_checkOutSelectedItem?.type==='product'){
    //     filteredProduct.push(_checkOutSelectedItem?._id)
    //   }else{
    //     filteredService.push(_checkOutSelectedItem?.selectedService?.serviceId)
    //   }
    // })

    _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
      if(_checkOutSelectedItem?.type==='product'){
        filteredProduct.push({..._checkOutSelectedItem,id:_checkOutSelectedItem?._id,retailPrice:parseFloat(_checkOutSelectedItem?.retailPrice)})
      }else{
        filteredService.push({..._checkOutSelectedItem?.selectedService,id:_checkOutSelectedItem?.selectedService?.serviceId,price:_checkOutSelectedItem?.selectedService?.servicePrice});
      }
    })

    if(filteredProduct?.length>0 || filteredService?.length>0) {
      self.setState({
        productList: filteredProduct,
        serviceList: filteredService,
      })
      let totalPaymentAmount =
          parseFloat(this.state.totalPaymentAmount) +
          parseFloat(this.state.pendingAmount);
      let paramsCheckOut = {
        hairdresserId: self.state.selectedStaff?.value,
        salonId: self?.props?.match?.params?.salonId,
        servicesBooked: filteredService,
        products: filteredProduct,
        isCheckout: 1,
        bookingStatus: 1,
        totalAmount: totalPaymentAmount,
        userId: this.state.selectedClient._id,
        transaction: JSON.stringify(transaction)
      }

      self.props.dispatch(bookingActions?.createNewCheckoutBooking(paramsCheckOut))
          .then(res => {
            self.setState({
              bookingId: res.data?.responseData?.result?._id
            },()=>{
              // localStorage.setItem('bookingId',res.data?.responseData?.result?._id);
              if(callback!=''&& callback!=undefined){
                callback();
              }
            })
          })
    }
}


editCheckoutBooking=()=>{
  let self=this;
  let filteredProduct = [];
  let filteredService = [];
  let transaction = this.state.transaction;
  _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
    if(_checkOutSelectedItem?.type==='product'){
      filteredProduct.push({..._checkOutSelectedItem,id:_checkOutSelectedItem?._id,retailPrice:parseFloat(_checkOutSelectedItem?.retailPrice)})
    }else{
      filteredService.push({..._checkOutSelectedItem?.selectedService,id:_checkOutSelectedItem?.selectedService?.serviceId,price:_checkOutSelectedItem?.selectedService?.servicePrice});
    }
  })

  // _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
  //   if(_checkOutSelectedItem?.type==='product'){
  //     filteredProduct.push(_checkOutSelectedItem?._id)
  //   }else{
  //     filteredService.push(_checkOutSelectedItem?.selectedService?.serviceId)
  //   }
  // })

  if(filteredProduct?.length>0 || filteredService?.length>0) {
    self.setState({
      productList: filteredProduct,
      serviceList: filteredService,
    })
    let totalPaymentAmount =
        parseFloat(this.state.totalPaymentAmount) +
        parseFloat(this.state.pendingAmount);
    let paramsCheckOut = {
      bookingId:self.state.bookingId,
      hairdresserId: self.state.selectedStaff?.value,
      salonId: self?.props?.match?.params?.salonId,
      servicesBooked: filteredService,
      products: filteredProduct,
      isCheckout: 1,
      totalAmount: totalPaymentAmount,
      userId: this.state.selectedClient._id,
      transaction: JSON.stringify(transaction)
    }

    self.props.dispatch(bookingActions?.editCheckoutBooking(paramsCheckOut))
        .then(res => {
          self.setState({
            bookingId: res.data?.responseData?.result?._id
          })
        })
  }
}


  checkCompleteSale = () => {
    if (this.state.isCheckoutPayment === true) {
      return true;
    }
    if (this.state.pendingAmount == 0) {
      return false;
    }
    if (this.state.selectedPaymentType !== null) {
      return false;
    }
    if (
      this.state.checkOutAmountType == 1 &&
      this.state.isCardReaderConnected === true
    ) {
      return false;
    }
    return true;
  };

  completeSale() {
    let self = this;
    // console.log('check the cardreader',this.state.selectedCardReader)
    // return;
    this.setState({
      paymentAmountError: "",
    });

    let filteredProduct = [];
    let filteredService = [];
    if(self?.state?.checkoutSelectedItems?.length>0){
      // _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
      //   if(_checkOutSelectedItem?.type==='product'){
      //     filteredProduct.push(_checkOutSelectedItem?._id)
      //   }else{
      //     filteredService.push(_checkOutSelectedItem?.selectedService?.serviceId)
      //   }
      //  })

      _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
        if(_checkOutSelectedItem?.type==='product'){
          filteredProduct.push({..._checkOutSelectedItem,id:_checkOutSelectedItem?._id,retailPrice:parseFloat(_checkOutSelectedItem?.retailPrice)})
        }else{
          filteredService.push({..._checkOutSelectedItem?.selectedService,id:_checkOutSelectedItem?.selectedService?.serviceId,price:_checkOutSelectedItem?.selectedService?.servicePrice});
        }
        })

       if(filteredProduct?.length>0 || filteredService?.length>0){
        self.setState({
          productList: filteredProduct,
          serviceList: filteredService,
        })

          if (this.state.pendingAmount === 0) {
            if (this.state.isPaymentComplete === 0) {
              this.setState({
                isPaymentComplete: 1,
              });
            } else {
              this.setState({ isInvoice: 1 }, this.editCheckoutBooking());
            }

         
          } else {

    // panding amount code
    if (this.state.selectedAmount > 0) {
      let transaction = this.state.transaction;
          transaction.push({
            paymentType: self.state.selectedPaymentType,
            amount: parseFloat(this.state.selectedAmount),
          });
          let totalPaymentAmount =
              parseFloat(this.state.totalPaymentAmount) +
              parseFloat(this.state.selectedAmount);
          let pendingAmount =
              parseFloat(this.state.totalAmount) - totalPaymentAmount;
          let isPaymentComplete = 0;
          if (pendingAmount === 0) {
            isPaymentComplete = 1;
          }
          this.setState({
            totalPaymentAmount,
            transaction: transaction,
            pendingAmount: pendingAmount,
            selectedAmount: "",
            selectedPaymentType: null,
            checkOutAmountType: true,
            isPaymentComplete,
          },function (){
            self.createBooking();
          });

        }else{
          let transaction = this.state.transaction;
            if (this.state.checkOutAmountType == true) {
              transaction.push({
                paymentType: self.state.selectedPaymentType,
                amount: parseFloat(this.state.pendingAmount),
              });
              let totalPaymentAmount =
                parseFloat(this.state.totalPaymentAmount) +
                parseFloat(this.state.pendingAmount);
              let paramsCheckOut = {
                hairdresserId:self.state.selectedStaff?.value,
                salonId:self?.props?.match?.params?.salonId,
                servicesBooked:filteredService,
                products:filteredProduct,
                isCheckout:1,
                totalAmount:totalPaymentAmount,
                userId:this.state.selectedClient._id,
                transaction:JSON.stringify(transaction),
                bookingStatus: 4,
               }

               self.props.dispatch(bookingActions?.createNewCheckoutBooking(paramsCheckOut)).then(res=>{
                if(res?.data?.statusCode===1){
                  self.props.dispatch(productAction?.selectedClient({}));
                  self.props.dispatch(productAction?.selectedCheckoutList([]));
                  self.props.history.push('/invoice/'+res.data?.responseData?.result?._id);
                }
                }).catch((error)=>{
                  console.log('complete sales error',error)
               })
            }
        }


            
          }

       }else{
        this.setState({
          paymentAmountError: "Please select service or product.",
        });
       }
    }

    if(parseFloat(this.state.selectedAmount)<=0){
      this.setState({
          paymentAmountError:'Please enter some amount'
      })
      }else {
            this.setState({isInvoice: 1})
        }
  }

  completeSaleForCardReader=()=> {


    this.setState({
      paymentAmountError: "",
    });
    let self = this;

      if (this.state.pendingAmount === 0) {
      if (this.state.isPaymentComplete == 0) {
        this.setState({
          isPaymentComplete: 1,
        });
      } else {
        this.editCheckoutBooking()
      }
    } else {
      let transaction = this.state.transaction;
      if (this.state.checkOutAmountType == true) {
        transaction.push({
          paymentType: self.state.selectedPaymentType,
          amount: parseFloat(this.state.pendingAmount),
        });
        let totalPaymentAmount =
            parseFloat(this.state.totalPaymentAmount) +
            parseFloat(this.state.pendingAmount);
        let pendingAmount =
            parseFloat(this.state.totalAmount) - totalPaymentAmount;
        let isPaymentComplete = 0;
        if (pendingAmount === 0) {
          isPaymentComplete = 1;
        }
        this.setState({
          totalPaymentAmount,
          transaction: transaction,
          pendingAmount: pendingAmount,
          selectedPaymentType: null,
          isPaymentComplete,
        });
      } else {
        if (
            parseFloat(this.state.selectedAmount) >
            parseFloat(this.state.totalAmount) -
            parseFloat(this.state.totalPaymentAmount)
        ) {
          this.setState({
            paymentAmountError: "Please enter valid amount",
          });
        } else if (this.state.selectedAmount == 0) {
          this.setState({
            paymentAmountError: "Please enter valid amount",
          });
        } else if (this.state.selectedAmount > 0) {

          transaction.push({
            paymentType: self.state.selectedPaymentType,
            amount: parseFloat(this.state.selectedAmount),
          });
          let totalPaymentAmount =
              parseFloat(this.state.totalPaymentAmount) +
              parseFloat(this.state.selectedAmount);
          let pendingAmount =
              parseFloat(this.state.totalAmount) - totalPaymentAmount;
          let isPaymentComplete = 0;
          if (pendingAmount === 0) {
            isPaymentComplete = 1;
          }
          this.setState({
            totalPaymentAmount,
            transaction: transaction,
            pendingAmount: pendingAmount,
            selectedAmount: "",
            selectedPaymentType: null,
            checkOutAmountType: true,
            isPaymentComplete,
          },function (){
            self.createBooking();
          });

        }
      }
    }
  }

  setPaymentType = (paymentType) => {
    console.log('check payment type',paymentType)
    this.setState({
      payoutError: "",
      cardReaderConnectError: "",
      selectedCardReader: {},
      selectedPaymentType: paymentType,
      isCardReaderConnected: false,
    });
  };

  selectBookingAmount = (type) => {
    let self = this;
    if (type == 1) {
      self.setState({
        checkOutAmountType: true,
      });
    } else {
      self.setState({
        checkOutAmountType: false,
      });
    }
  };
  removeTransaction = (transaction) => {
    let transactionList = this.state.transaction;
    let newList = _.pull(transactionList, transaction);
    let totalPaymentAmount =
      parseFloat(this.state.totalPaymentAmount) -
      parseFloat(transaction.amount);
    let pendingAmount = parseFloat(this.state.totalAmount) - totalPaymentAmount;
    this.setState({
      totalPaymentAmount,
      pendingAmount,
      transaction: newList,
    });
  };

  cardReaderList = (hairdresserInfo) => {
    let self = this;
    self.props
      .dispatch(paymentActions.cardReaderList({}))
      .then(function (response) {
        console.log("cardReaderList", response);
        if (response.data.statusCode === 1) {
          self.setState(
            {
              cardReaderList: self.props.cardReaderList,
            },
            function () {
              if (hairdresserInfo?.hairdresser?.isSalonOwner === 1) {
                let count = 0;
                const names = self.state.cardReaderList.map((obj) => {
                  count++;
                });
                self.setState({
                  label: hairdresserInfo?.hairdresser?.salon.salonName
                    ? hairdresserInfo?.hairdresser?.salon?.salonName +
                      ` Card Reader (${count + 1})`
                    : "",
                });
              }
            }
          );
        } else {
        }
      });
  };

  setPaymentCardReader = (cardReader) => {
    let self = this;
    console.log('check the card reader data',cardReader);
    this.setState(
      {
        payoutError: "",
        cardReaderConnectError: "",
        selectedPaymentType: null,
        selectedCardReader: cardReader,
      },
      function () {
        self.discoverReaders();
      }
    );
  };

  checkout = () => {
    let self = this;
    let _bookingId = localStorage.getItem('bookingId')!=undefined&&localStorage.getItem('bookingId')!=null?localStorage.getItem('bookingId'):self.state.bookingId;

    console.log('check booking id',_bookingId)

    if(_bookingId==''|| _bookingId==undefined){
      self.createBooking(self.updatedCheckOut.bind(this));
    }else{
      self.updatedCheckOut();
    }
  };

  updatedCheckOut = () =>{
    let self = this;
    if (this.state.isPaymentComplete === 1) {
      // this.setState({ isInvoice: 1 });
      let client = {};
      self.props.dispatch(bookingActions?.hairdresserNewBookingClient(client));
      self.props.dispatch(productAction.selectedClient(client));
      self.props.dispatch(hairdresserActions.selectedService({}));
      self.props.dispatch(bookingActions?.hairdresserNewBookingStaff({}));
      self.props.dispatch(bookingActions?.hairdresserNewBookingData([]));
      self.props.dispatch(productAction?.selectedCheckoutList([]));
      // localStorage.removeItem('bookingId')
      self.props.history.push('/invoice/' + self.state.bookingId);
    } else {
      self.setState({
        isCheckoutPayment: true,
      });

      let filteredProduct = [];
      let filteredService = [];
    
        _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
        if(_checkOutSelectedItem?.type==='product'){
          filteredProduct.push({..._checkOutSelectedItem,id:_checkOutSelectedItem?._id,retailPrice:parseFloat(_checkOutSelectedItem?.retailPrice)})
        }else{
          filteredService.push({id:_checkOutSelectedItem?.selectedService?.serviceId,price:_checkOutSelectedItem?.selectedService?.servicePrice});
        }
      })

      // let servicesToBook = _.map(self.state.selectedService, function (o) {
      //   return {id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.serviceId, price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
      // });

      // let productIds = _.map(this.state.productList, function (o) {
      //   return {...o,id:o._id,retailPrice:parseFloat(o?.retailPrice)};
      // });

      // console.log('checkCheckout Data',filteredProduct,filteredService);
      // return ;

      // let servicesToBook = _.map(self.state.selectedService, function (o) {
      //   return o?.selectedService?.serviceId;
      // });

      // this function running card payment 
      self.props
          .dispatch(
              paymentActions.paymentIntents({
                bookingId: this.state.bookingId,
                servicesToBook:filteredService,
                productIds:filteredProduct
              })
          )
          .then(function (response) {
            let transaction = self.state.transaction;
            if(response.data.statusCode===0){
              if( response?.data?.error?.errorCode===10 || response?.data?.error?.errorCode===5 ){
                if(response.data.error.errors.errorCode){
                  self.setState({
                    isCheckoutPayment: false,
                    isCardReaderConnected:false,
                  },()=>{
                    toast.error(response?.data?.error?.errors?.responseMessage);
                  })

                }else{
                  self.setState({
                    isCheckoutPayment: false,
                    isCardReaderConnected:false,
                  },()=>{
                    toast.error(response.data.error.responseMessage);
                  })

                }

                // setTimeout(()=>{
                //   window.location.reload();
                // },4000)
              }
            }
            if (response.data.statusCode === 1) {
              self.setState({
                paymentIntents: response.data.responseData.result,
              });
              let clientSecret = response.data.responseData.result.client_secret;

              terminal.collectPaymentMethod(clientSecret).then(function (result) {
                console.log("collectPaymentMethod_checkout", result);
                if (result.error) {
                  // Placeholder for handling result.error
                  self.setState({
                    isCheckoutPayment: false,
                    payoutError: result.error.message,
                  });
                } else {
                  if (
                      result?.paymentIntent?.amount_details?.tip?.amount !=
                      undefined
                  ) {
                    let tip_amount =
                        result?.paymentIntent?.amount_details?.tip?.amount / 100;
                    self.setState({
                      tipAmount: tip_amount,
                    });
                  } else {
                    console.log(
                        "result_paymentIntent_",
                        self.state.bookingId,
                        result.paymentIntent
                    );
                  }
                  let capturedAmount =
                      response?.data?.responseData?.result?.amount / 100;
                  const filterPaymentType = transaction.filter(
                      (_transaction) => _transaction?.paymentType !== 5
                  );
                  filterPaymentType.push({
                    paymentType: 5,
                    amount: parseFloat(capturedAmount),
                  });
                  self.setState({
                    transaction: filterPaymentType,
                  });
                  self.processPayment(result.paymentIntent);
                  // Placeholder for processing result.paymentIntent
                }
              });
            } else {

            }
          });
    }
  }

  discoverReaders = async () => {
    let self = this;
    this.setState({
      activeCLass: true,
      isSearchReader: true,
      isShowConnectionLoader: true,
    });
    const config = {
      simulated: siteSetting.simulated,
      location: this.state.locationId,
    };
    let StripeTerminal = await loadStripeTerminal();
    terminal = StripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: unexpectedDisconnect,
    });
    console.log("terminal", terminal);
    terminal.discoverReaders(config).then(function (discoverResult) {
      console.log("discoverReaders !!!: ", discoverResult);
      if (discoverResult.error) {
        console.log("Failed to discover ###: ", discoverResult.error);
        self.setState({
          isCardReaderConnected: false,
          cardReaderConnectError: discoverResult.error.message,
        });
      } else if (discoverResult.discoveredReaders.length === 0) {
        self.setState({
          noDevice: true,
        });
        console.log("No available readers.");
        self.setState({
          selectedCardReader: {},
          isCardReaderConnected: false,
          cardReaderConnectError: "No available readers",
        });
      } else {
        console.log("selectedCardReader", self.state.selectedCardReader);
        console.log(
          "discoveredReaders[0]",
          discoverResult.discoveredReaders[0]
        );
        let result = _.find(discoverResult.discoveredReaders, function (obj) {
          if (
            obj.serial_number === self.state.selectedCardReader.serial_number
          ) {
            return true;
          }
        });
        console.log("finalselectedCardReader", result);
        if (result) {
          self.connectReader(result);
        } else {
          self.connectReader(discoverResult.discoveredReaders[0]);
        }
        self.setState({
          discoverResult: discoverResult.discoveredReaders,
          cardReaderConnectError:"",
          payoutError:""
        });
      }
    });
  };

  connectReader = async (selectedReader) => {
    console.log("connect card reader function");
    let self = this;

    await terminal.disconnectReader();
    terminal.connectReader(selectedReader).then(function (connectResult) {
      if (connectResult.error) {
        self.setState({
          isCardReaderConnected: false,
          cardReaderConnectError: connectResult.error.message,
        });
      } else {
        //self.cardReaderList();
        self.setState({
          isCardReaderConnected: true,
          isShowConnectionLoader: false,
          cardReaderConnectError:"",
          payoutError:""
        });
      }
    });
  };

  processPayment = (paymentIntent) => {
    // transactions
    let self = this;
    terminal.processPayment(paymentIntent).then(function (result) {
      if (result.error) {
        self.setState({
          isCheckoutPayment: false,
          payoutError: result.error.message0,
        });
      } else if (result.paymentIntent) {
        let filteredProduct = [];
        let filteredService = [];
      
        //   _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
        //   if(_checkOutSelectedItem?.type==='product'){
        //     filteredProduct.push(_checkOutSelectedItem?._id)
        //   }else{
        //     filteredService.push(_checkOutSelectedItem?.selectedService?.serviceId)
        //   }
        // })

        _.forEach(self?.state?.checkoutSelectedItems, (_checkOutSelectedItem,i)=>{
          if(_checkOutSelectedItem?.type==='product'){
            filteredProduct.push({..._checkOutSelectedItem,id:_checkOutSelectedItem?._id,retailPrice:parseFloat(_checkOutSelectedItem?.retailPrice)})
          }else{
            filteredService.push({id:_checkOutSelectedItem?.selectedService?.serviceId,price:_checkOutSelectedItem?.selectedService?.servicePrice});
          }
        })

   
        self.props
          .dispatch(
            paymentActions.paymentIntentsCapture({
              bookingId: self.state.bookingId,
              paymentIntentId: result.paymentIntent.id,
              servicesToBook:filteredService,
              productIds:filteredProduct,
            })
          )

          .then(function (response) {
            if(response.data.statusCode===0){
              if( response?.data?.error?.errorCode===10 || response?.data?.error?.errorCode===5 ){
                if(response.data.error.errors.errorCode){
                  self.setState({
                    isCheckoutPayment: false,
                    isCardReaderConnected:false,
                  },()=>{
                    toast.error(response?.data?.error?.errors?.responseMessage);
                  })

                }else{
                  self.setState({
                    isCheckoutPayment: false,
                    isCardReaderConnected:false,
                  },()=>{
                    toast.error(response.data.error.responseMessage);
                  })

                }
                // setTimeout(()=>{
                //   window.location.reload();
                // },4000)
              }
            }
            let _tipAmount =
              response.data?.responseData.result.tipAmount != undefined
                ? response.data?.responseData.result.tipAmount
                : 0;
            if (response.data.statusCode === 1) {
              self.setState({
                isPaymentComplete: 1,
                isCheckoutPayment: false,
                tipAmount: _tipAmount,
              });
            }
          });
      }
    });
  };

  addStripeTerminal = () => {
    let self = this;
    self.props
      .dispatch(
        paymentActions.addTerminal({
          registrationCode: this.state.registrationCode,
          label: this.state.label,
        })
      )
      .then(function (response) {
        if (response.data.statusCode === 1) {
          self.cardReaderList();
          self.handleClose();
        } else {
          //setCardReaderConnectError(response.data.error.responseMessage)
        }
      });
  };

 addCheckoutItem(type){
  let self = this;
  let dispatch = self.props?.dispatch;
  let filterData = self.state.checkoutSelectedItems.filter(item=>(typeof item.id!=='string'));
  let lastIndex = filterData.length + 1;
  if(type==='product'){
    const newProduct = {
      id: lastIndex,
      type:"product",
      selectedStaff:null
    };

    dispatch(productAction.selectCheckoutItem({}));
    if(filterData?.length==0){
      self.setState({
        selectedStaff:null,
        selectedClient: siteSetting.noClientDAta,
      })
    }

    let productData = [...filterData, newProduct];
    self.setState({
      checkoutSelectedItems:productData,
    },()=>{
      dispatch(productAction?.selectedCheckoutList(productData));
    });
  }else{
    const newService = {
      id: lastIndex,
      type:"service",
      selectedStaff:null
    };

    if(filterData?.length==0){
      self.setState({
        selectedStaff:null,
        selectedClient: siteSetting.noClientDAta,
      })
    }
    
    let serviceData = [...filterData, newService];
    dispatch(productAction?.selectedCheckoutList(serviceData));
    self.setState({
      checkoutSelectedItems:serviceData,
    });
  }
 }

 removeCheckoutItem = (type,selectedItemId) =>{
  let self = this;
  let dispatch = self.props?.dispatch;
   if(type==='product' || type==='service'){
    let updatedProductList = self.state.checkoutSelectedItems?.filter(productItem=>productItem?.id!==selectedItemId);
    dispatch(productAction?.selectedCheckoutList(updatedProductList));
    self.setState({
      checkoutSelectedItems:updatedProductList
    },()=>{
      self.calculateCheckoutItem();
    })
   }
 }

  staffMemberHandler(name) {
    this.setState({
      hairDresserName: name,
    });
  }

  onSelectStaff = (type,staffRecord,indexId) =>{
    let self = this;
    let dispatch = self.props?.dispatch;
     if(type==='product' || type==='service'){
      let _hairdresserId = staffRecord?.value!==undefined?staffRecord?.selectedStaff?.value:'';
        const checkOutItem = {
          id: 1,
          type:type,
          selectedStaff:staffRecord
        };
        let serviceData = [checkOutItem];
        dispatch(bookingActions?.hairdresserNewBookingStaff({_id:staffRecord?.value,name:staffRecord?.label}));
        dispatch(productAction?.selectedCheckoutList(serviceData));
        self.setState({
          checkoutSelectedItems:serviceData,
          hairdresserId:_hairdresserId,
          selectedStaff:staffRecord
        });
     }
  }

  changeItemPrice(_item,type,price,err){
    let self = this;
    let  priceStatusCount = 0;
    self.setState({
      priceError:err
     });
    if(type==='service'){
    let updatedPriceData = self.props.selectedCheckoutList.map((_serviceData)=>{
       if(_serviceData.selectedService?.indexId===_item?.selectedService?.indexId){
         _serviceData.selectedService.servicePrice =parseFloat(price);
       }
       return _serviceData;
      })

      let totalAmount = updatedPriceData.reduce((prev,curr)=>{
             if(curr?.selectedService?.retailPrice==='' || curr?.selectedService?.servicePrice===''){
               priceStatusCount++
             }
              if(curr?.selectedService?.servicePrice!==undefined){
               prev= prev+ parseFloat(curr?.selectedService?.servicePrice===''?0:curr?.selectedService?.servicePrice);
               return prev;
              }else{
               prev= prev+ parseFloat(curr?.retailPrice===''?0:curr?.retailPrice);
               return prev;
              } 
      },0);

      self.setState({
       totalAmount:totalAmount,
       pendingAmount:totalAmount,
       priceErrorStatus:priceStatusCount>0?false:true,
       selectedTotalAmount:totalAmount
      })
      self.props.dispatch(productAction?.selectedCheckoutList(updatedPriceData));
    }else{
     let updatedPriceData = self.props.selectedCheckoutList.map((_serviceData)=>{
       if(_serviceData?.id===_item?.id){
         _serviceData.retailPrice =parseFloat(price)
       }
       return _serviceData;
      })

      let totalAmount = updatedPriceData.reduce((prev,curr)=>{

       if(curr?.retailPrice==='' || curr?.servicePrice===''){
         priceStatusCount++
       }

       if(curr?.retailPrice!==undefined){
         prev= prev+ parseFloat(curr?.retailPrice===''?0:curr?.retailPrice);
         return prev;
       }else{
         prev= prev+ parseFloat(curr?.selectedService?.servicePrice===''?0:curr?.selectedService?.servicePrice);
         return prev;
       }     
      },0);

      self.setState({
       totalAmount:totalAmount,
       pendingAmount:totalAmount,
       priceErrorStatus:priceStatusCount>0?false:true,
       selectedTotalAmount:totalAmount
      })

      self.props.dispatch(productAction?.selectedCheckoutList(updatedPriceData));
    }
 }

 updateNewEditAmount(){
  let self = this;
  let params = {};
  params.hairdresserId = self.state.hairdresserId;
  params.salonId = self.state.salonId;
  params.bookingId = self.state.bookingId;
  params.services = _.map(this.state.selectedService, function (o) {
    return {id:o?.service?.serviceId!=undefined?o?.service?.serviceId:o?.serviceId, price:parseFloat(o?.service?.servicePrice!=undefined?o?.servicePrice:o?.service?.servicePrice)};
  });
  params.products = _.map(this.state.productList, function (o) {
    return {...o,id:o._id,retailPrice:parseFloat(o?.retailPrice)};
  });

  return self.props.dispatch(bookingActions?.editProductServiceBooking(params))

 }

  render() {
   console.log("this.props",this.props);
    console.log("this.state",this.state);
    return (
      <div>
        <div className="h_n">
          <div className="body_container cm_bg_white">
            <TopHeader title="Checkout"
            headerButtonStatus={true}
            cancelButton={true}
            {...this.props} />
            <SideNav {...this.props} />
             {/* <BookingNavigation data={this.props} bookingUrl={`/newBooking/${this.props.match.params.salonId}`} {...this.props}/> */}
            <div className="service_flex_div new__checkout_page service_flex__space">
              <div
                className={
                  this.state.isInvoice === 0
                    ? "main_service_div"
                    : "main_service_div"
                }
              >
              {console.log('check the data',((this.props.selectedCheckoutList[0]?.type=='product'&&this.props.selectedCheckoutList[0]?._id!=undefined)||(this.props.selectedCheckoutList[0]?.selectedService?._id!=undefined && this.props.selectedCheckoutList[0]?.type=='service'))
             
              )}
              {this.props.selectedCheckoutList?.length > 0
                      ? this.props.selectedCheckoutList.map((item, i) => (
                          (item?.type==='service'?<CheckoutService
                            key={"service_" + i}
                            item={item}
                            index={i+1}
                            removeService={this.removeCheckoutItem}
                            propData={this.props}
                            staffList={this.state.staffList}
                            onSelectStaff={this.onSelectStaff}
                            selectedStaff={this.state.selectedStaff}
                            selectedClient={this.state.selectedClient}
                            isPaymentComplete={this.state.isPaymentComplete}
                            changeItemPrice={this.changeItemPrice}
                            finalBookingSlots={this?.state?.finalBookingSlots}
                          />:<CheckoutProduct
                          index={i+1}
                          key={"products_" + i}
                          item={item}
                          hairdresserName={this.state.hairdresserName}
                          removeProduct={this.removeCheckoutItem}
                          handleClickProduct={this.handleClickProduct}
                          staffList={this.state.staffList}
                          propData={this.props}
                          onSelectStaff={this.onSelectStaff}
                          selectedStaff={this.state.selectedStaff}
                          selectedClient={this.state.selectedClient}
                          isPaymentComplete={this.state.isPaymentComplete}
                          changeItemPrice={this.changeItemPrice}
                          finalBookingSlots={this?.state?.finalBookingSlots}
                        />)
                        ))
                      : null}
               
                  {this.state.isPaymentComplete===1?null:<div className="row">
                    <div className="col-md-11">
                   
                      <div className="button-group-service">
                      {this?.state?.priceError==''?<button className="btn btn-default bg-light text-dark"
                      onClick={()=>this.addCheckoutItem('service')} > Add Service </button>
                      :<button className="btn btn-default bg-light text-dark cursor-notAllow" > Add Service </button>
                      }
                        
                  {this?.state?.priceError==''?<button
                  className="btn btn-default bg-light text-dark"
                  onClick={()=>this.addCheckoutItem('product')}
                >
                  Add Product
                </button>:<button
                className="btn btn-default bg-light text-dark cursor-notAllow"
              >
                Add Product
              </button>}
                        
                      </div>
                    </div>
                  </div>}
              </div>
              
              {((this.props.selectedCheckoutList[0]?.type=='product'&&this.props.selectedCheckoutList[0]?._id!=undefined)||(this.props.selectedCheckoutList[0]?.selectedService?._id!=undefined && this.props.selectedCheckoutList[0]?.type=='service'))?(<>
                <div
                className={"checkout_page"}
              >
              {this?.state?.priceError!=''?<div className="overlay-wrapper-checkout"></div>:null}
                   <Checkout
                      checkCompleteSale={this.checkCompleteSale}
                      removeTransaction={this.removeTransaction}
                      selectBookingAmount={this.selectBookingAmount}
                      bookingState={{
                        ...this.state,
                        pendingAmount: this.state.pendingAmount,
                      }}
                      setPaymentType={this.setPaymentType}
                      saveBooking={this.saveBooking}
                      onChangedValue={this.onChanged}
                      pendingAmount={this.state.selectedTotalAmount}
                      currencySymbol={this.state.currencySymbol}
                      paymentAmountError={this.state.paymentAmountError}
                      selectedClient={this.state.selectedClient}
                      selectedService={this.state.selectedService}
                      totalAmount={this.state.selectedTotalAmount}
                      paymentType={this.state.paymentType}
                      resetBooking={this.resetBooking}
                      paymentAmount={this.state.selectedTotalAmount}
                      completeSale={this.completeSale}
                      hairdresserId={this.state.hairdresserId}
                      bookings={
                        this.props?.hairdresserBooking?.bookings &&
                        this.props?.hairdresserBooking?.bookings[0]
                      }
                      cardReaderList={this.props.cardReaderList}
                      setPaymentCardReader={this.setPaymentCardReader}
                      checkout={this.checkout}
                      selectedStaff={this.state.selectedStaff}
                      addStripeTerminal={this.addStripeTerminal}
                      handleClose={this.handleClose}
                      handleDiscoverModal={this.handleDiscoverModal}
                    />
               
              </div></>):null}
            </div>
            {/* <div className="cm_loader"><div className="loader"></div></div> */}
            <br></br>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(InstantCheckout);
