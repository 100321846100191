import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import TopHeader from "../template/TopHeader";
import SideNav from "../template/SideNav";
import { connect, useDispatch, useSelector } from "react-redux";
import animationData from '../../assets/animation-data/loader.json'
import Lottie from 'react-lottie';
import { Addicon, Mail, Msz } from "../SvgIcon";
import SettingSidebar from "../settings/SettingSidebar";
import AddCard from '../addCardPopup/addCard';
import { marketingAction } from "../../_actions/marketing.actions";
import toast from "react-toast-notification";


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
  }
};

function Marketing(props) {
  const dispatch = useDispatch();
  const router = useHistory();
  const [marketingSetting, setMarketingSetting] = useState([]);
  const getSalonMarketingSetting = useSelector(state=>state?.getSalonMarketingSetting);
  const userInfo = useSelector(state=>state?.user?.userInfo);
  const [salonId, setSalonId] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  const[showmodal,setShowModal]=useState(false)
  const[salonData,setSalonData]=useState([])


  const hideModal =()=>{
    setShowModal(false)
  }
  const showModal = ()=>{
    setShowModal(true)
  }
  useEffect(() => {
    // Add or remove class from body based on modal state
    if (showmodal) {
      document.body.classList.add('add_card_modal_open');
    } else {
      document.body.classList.remove('add_card_modal_open');
    }
  }, [showmodal]);
  useEffect(() => {
      const salonId = localStorage.getItem('salonId');
      setSalonId(salonId);
      getSettings();
      getMarketingSetting();

   /* eslint-disable-next-line */
  }, []);

  const getSettings = () => {
    dispatch(marketingAction.getSettings()).then((res) => {
      if (res.data.statusCode === 1) {
        if (res?.data?.responseData?.result!==undefined && res?.data?.responseData?.result!=null) {
          let data = res?.data?.responseData?.result;
          data.bookingUpdate = transformData("booking", data?.bookingUpdate);
          data.salesUpdate = transformData("sales", data?.salesUpdate);
          data.reviewBooster = transformData("reviewBooster", data?.reviewBooster);
          console.log('check marketting setting data',data);
          setMarketingSetting(data);
        }
        setIsLoader(false);
      }else{
        setIsLoader(false);
      }
    });
  };

  const transformData = (type, data) => {
    if (data.length < 1) {
      return [];
    }

    switch (type) {
      case "booking":
        const bookingCategoryData = [
          { id:1,
            title: "New booking",
            note: "Send client details when you book their appointment for them.",
          },
          { id:2,
            title: "Updated booking",
            note: "Send client details if you update their date, time or services.",
          },
          { id:3,
            title: "No-show",
            note: "Let your client know that they missed their appointment.",
          },
          { id:4,
            title: "Cancelled booking",
            note: "Let your client know that you cancelled their appointment.",
          },
          { id:5,
            title: "Thank you after visiting",
            note: "Thank your client for visiting and encourage a rebooking.",
          },
          { id:6,
            title: "Thank you for tipping",
            note: "Let your client know how much their tip means to you.",
          },
        ];
        return data.map((record, index) => {
          record.id = bookingCategoryData[index]?.id;
          record.title = bookingCategoryData[index]?.title;
          record.note = bookingCategoryData[index]?.note;
          return record;
        });

      case "sales":
        const salesCategoryData = [
          { id:1,
            title: "Reminder to rebook",
            note: "Remind your client to rebook a set time after their last booking.",
          },
          { id:2,
            title: "Happy birthday",
            note: "Send your clients a happy birthday message.",
          }
        ];

        return data.map((sales, index) => {
          sales.id = salesCategoryData[index]?.id;
          sales.title = salesCategoryData[index]?.title;
          sales.note = salesCategoryData[index]?.note;
          return sales;
        });

      case "reviewBooster":
        const reviewBoosterData = [
          { id:1,
            title: "Google Review Booster",
            note: "Send a link for Google reviews after every appointment.",
          },
        ];

        return data.map((rebooster, index) => {
          rebooster.id = reviewBoosterData[index]?.id;
          rebooster.title = reviewBoosterData[index]?.title;
          rebooster.note = reviewBoosterData[index]?.note;
          return rebooster;
        });

      default:
        return;
    }
  };

  const updateStatus = (type, data, status) => {
    switch (type) {
      case "reminder":
        let reminderData = marketingSetting?.reminders?.map((reminder) => {
          if (reminder?._id === data?._id) {
            reminder.id = 1; //need to set dynamic value
            reminder.statusSMSreminder = status ? 1 : 0;
            reminder.statusEmailReminder = status ? 1 : 0;
            return reminder;
          }
          return reminder;
        });
        marketingSetting.reminders = reminderData;

        setMarketingSetting({ ...marketingSetting });
        updateSetting({ ...marketingSetting });
        break;

      case "booking":
        let bookingData = marketingSetting?.bookingUpdate?.map((booking) => {
          if (booking?._id === data?._id) {
            booking.statusSMSreminder = status ? 1 : 0;
            booking.statusEmailReminder = status ? 1 : 0;
            return booking;
          }
          return booking;
        });

        marketingSetting.bookingUpdate = bookingData;
        setMarketingSetting({ ...marketingSetting });
        updateSetting({ ...marketingSetting });
        break;

      case "sales":
        let salesData = marketingSetting?.salesUpdate?.map((sales) => {
          if (sales?._id === data?._id) {
            sales.statusSMSreminder = status ? 1 : 0;
            sales.statusEmailReminder = status ? 1 : 0;
            return sales;
          }
          return sales;
        });

        marketingSetting.salesUpdate = salesData;
        setMarketingSetting({ ...marketingSetting });
        updateSetting({ ...marketingSetting });
        break;

      case "custom":
        let customData = marketingSetting?.customUpdate?.map((custom) => {
          if (custom?._id === data?._id) {
            custom.statusSMSreminder = status ? 1 : 0;
            custom.statusEmailReminder = status ? 1 : 0;
            return custom;
          }
          return custom;
        });
        marketingSetting.customUpdate = customData;
        setMarketingSetting({ ...marketingSetting });
        updateSetting({ ...marketingSetting });
        break;

      case "reviewBooster":
        let reviewBooster = marketingSetting?.reviewBooster?.map((item) => {
          if (item?._id === data?._id) {
            item.statusSMSreminder = status ? 1 : 0;
            item.statusEmailReminder = status ? 1 : 0;
            return item;
          }
          return item;
        });

        marketingSetting.reviewBooster = reviewBooster;
        setMarketingSetting({ ...marketingSetting });
        updateSetting({ ...marketingSetting });
        break;

      default:
        return;
    }
  };

  const updateSetting = (data) => {
    data.type = 1;
    dispatch(marketingAction.updateSetting(data)).then((res) => {
      if (res.data.statusCode === 1) {
      }
    });
  };

  const goToUpdate =(type,data,index)=>{
    switch(type){
      case 'reminder':
        let editStatus =true;
        const reminderData ={
          id:data?.id,
          reminder: data,
          editStatus:editStatus,
          previousUrl:router?.location?.pathname,
          salonId:salonId,
          index:index,
          title:index===0?'Reminder':'Reminder '+(index+1),
        }

       dispatch(marketingAction.marketingUpdateSettingData(reminderData));
       router.push({pathname: "/marketing/reminder-update"});
       break;

      case 'updateBooking':
        const bookingData ={
          bookingData: data,
          editStatus:true,
          previousUrl:router?.location?.pathname,
          salonId:salonId
        }
        

       dispatch(marketingAction.marketingUpdateSettingData(bookingData));
       router.push({pathname: "/marketing/booking-update"});
       break;

       case 'sales':
        const salesData ={
          reminder: data,
          editStatus:true,
          previousUrl:router?.location?.pathname,
          salonId:salonId
        }
       dispatch(marketingAction.marketingUpdateSettingData(salesData));
       router.push({pathname: "/marketing/sales-update"});
       break;

       case 'custom':
        const customData ={
          reminder: data,
          editStatus:true,
          previousUrl:router?.location?.pathname,
          salonId:salonId
        }
       dispatch(marketingAction.marketingUpdateSettingData(customData));
       router.push({pathname: "/marketing/custom-reminder-update"});
       break;

      case 'reviewBooster':
        const reviewBoosterData ={
          reminder: data,
          editStatus:true,
          previousUrl:router?.location?.pathname,
          salonId:salonId
        }
        dispatch(marketingAction.marketingUpdateSettingData(reviewBoosterData));
        router.push({pathname: "/marketing/review-booster"});
        break;

      default:
       break;
    }
  }


  const addReminder = (type) => {
    if(type==='reminder'){
      router.push('/marketing/create-reminder',{
            editStatus:true,
            previousUrl:router.location.pathname,
            salonId:salonId,
        },
    )
    }

    if(type==='custom'){
      router.push('/marketing/create-custom-reminder',{
            editStatus:true,
            previousUrl:router.location.pathname,
            salonId:salonId,
        },
    )
    }
 };

 useEffect(() => {
  setSalonData(getSalonMarketingSetting);
/* eslint-disable-next-line */
}, [getSalonMarketingSetting]);

 const getMarketingSetting = () => {
  dispatch(marketingAction.getMarketingSetting()).then((res) => {
    if (res.data.statusCode === 1) {
      if (res?.data?.responseData?.result.length>0) {
        let data = res?.data?.responseData?.result;
        dispatch(marketingAction.setSalonMarketingSetting(data));
        if(props.user&&props.user?.userInfo&&props.user?.userInfo?.hairdresser?.isSalonOwner===0) {
          if ((data[0].salonConsent === 1 && data[0].selfEmpConsent === 1)) {
            dispatch(marketingAction?.marketingSettingNavigation(false))
            router.push('/marketing-setting');
          }
        }
      }
      setIsLoader(false);
    }else{
      setIsLoader(false);
    }
  });
};


  return (
    <div className="h_n">
      <div className="body_container cm_bg_white">
        <TopHeader 
        title="Marketing" 
        // headerButtonStatus={true}
        {...props} 
        />
        <SideNav {...props} />
        <AddCard />
        <div className="new-setting-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12 mobile-hidden">
              {/*{salonData.length>0?*/}
                  <SettingSidebar marketing={true}
                isOwner={userInfo?.hairdresser?.isSalonOwner}
                salonConsent={salonData[0]?.salonConsent}
                selfEmpConsent={salonData[0]?.selfEmpConsent}
                />
                   {/*:null}*/}
              </div>
              {isLoader?
                <div className='col-md-8'>
                    <div className='salon-body-main-wrp booking-urlpage'>
                        <div className='new-stng-cmn'>
                        <div className="text-center pr-md-5">
                            <Lottie options={defaultOptions}
                                    height={50}
                                    width={200}
                            />
                            <h4 className="text-center">Loading Data...</h4>
                        </div>
                        </div>
                    </div>
                </div>:
              <div className="col-md-8">
              {marketingSetting?.length===0?
                <div class="salon-body-main-wrp account--password-temp new-stng-cmn __marketing " style={{height:"300px"}}>
                  <div class="_marketing_setting">
                     <h4 className="text-center mt-4">Marketing setting not available.</h4>
                  </div>
                </div>
                :
                <div className="salon-body-main-wrp account--password-temp new-stng-cmn __marketing">
                <div className='text-right mb-4 d-flex justify-content-end'>
                                        <div className='_sms_priview'>
                                            <div>
                                                <p className='first'><span className='pr-3'>{marketingSetting.remainingSms}</span>sms remaining</p>
                                                <p className='last'>Free allowance renews on 1st of the month</p>
                                            </div>
                                        </div>
                                    </div>  
                <p>
                    Set up and manage your automated communications with your
                    clients to minimise no-shows, drive rebooking and maximise
                    profits.
                  </p>
                  <div className="">
                    {/* <ul className="services_tab pl-0">
                      <li className="active">
                        <Link to="#_reminders">Reminders</Link>
                      </li>
                      <li className="">
                        <Link to="#_updates">Updates</Link>
                      </li>
                      <li className="">
                        <Link to="#_sales">Sales</Link>
                      </li>
                      <li className="">
                        <Link to="#_custom">Custom</Link>
                      </li>
                    </ul> */}
                    <div
                      className={`_service_parent_body _marketting_parent`}
                      // id="_service_parent_body"
                      // ref={this.scrollDivRef}
                      // onScroll={this.handleScroll}
                    >
                      <div className="_service_parent " id="_reminders">
                        <h3>Reminder</h3>
                        <div className="_markt_body ">
                          {marketingSetting?.reminders?.length > 0
                            ? marketingSetting?.reminders?.map(
                                (reminder, index) => (
                                  <div
                                    key={"reminder" + reminder?._id}
                                    className={"__markt"}
                                    >
                                    <div className="cursor-pointer" onClick={() =>
                                      goToUpdate('reminder',reminder,index)
                                   }>
                                    <h6 className="mt-3">
                                      Reminder {index + 1 > 1 ? index + 1 : ""}
                                    </h6>
                                    <p className="">
                                      {reminder?.reminderTime} {marketingAction?.reminderTimeType(reminder?.reminderTimeType
                                        )} before
                                      appointment
                                    </p>
                                    </div>
                                    <div className="_bottom">
                                      <div
                                        className="_left"
                                        onClick={() =>
                                            goToUpdate('reminder',reminder,index)
                                        }
                                      >
                                        {reminder?.statusSMSreminder === 1 ? (
                                          <p className="mb-0 msz mr-2">
                                            <Msz />
                                          </p>
                                        ) : (
                                          <p className="mb-0 msz mr-2 disabled">
                                            <Msz />
                                          </p>
                                        )}
                                        {reminder?.statusEmailReminder === 1 ? (
                                          <p className="mb-0 msz mr-2">
                                            <Mail />
                                          </p>
                                        ) : (
                                          <p className="mb-0 msz mr-2 disabled">
                                            <Mail />
                                          </p>
                                        )}
                                      </div>
                                      <div className="_right">
                                        <div className="salon_switch_container">
                                          <label className="switch">
                                            <input
                                              type="checkbox"
                                              onChange={(e) =>
                                                updateStatus(
                                                  "reminder",
                                                  reminder,
                                                  e?.target?.checked
                                                )
                                              }
                                              checked={
                                                reminder?.statusSMSreminder ===
                                                  1 ||
                                                reminder?.statusEmailReminder ===
                                                  1
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            : null}
                          <div className={"__markt _create_new"}>
                            <div className="_new" >
                              <h6
                                className="mt-3 cursor-pointer"
                                onClick={() => addReminder('reminder')}
                               >
                                <Addicon />
                              </h6>
                              <p className="_create_new">Create New Reminder</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                          className="_service_parent _marketting_parent"
                          id="_reviewbooster"
                      >
                        <h3>Review Booster</h3>
                        <div className="_markt_body ">
                          {marketingSetting?.reviewBooster?.length > 0
                              ? marketingSetting?.reviewBooster.map((sales, i) => (
                                  <div
                                      key={"booking" + sales?._id}
                                      className={"__markt"}
                                  >
                                    <div className="cursor-pointer" onClick={() =>
                                        goToUpdate('reviewBooster',sales,i)
                                    }>
                                      <h6 className="mt-3">{sales?.title} </h6>
                                      <p className=""> {sales?.note}</p>
                                    </div>
                                    <div className="_bottom">
                                      <div className="_left"  onClick={() =>
                                          goToUpdate('reviewBooster',sales)
                                      }>
                                        {sales?.statusSMSreminder === 1 ? (
                                            <p className="mb-0 msz mr-2">
                                              <Msz />
                                            </p>
                                        ) : (
                                            <p className="mb-0 msz mr-2 disabled">
                                              <Msz />
                                            </p>
                                        )}
                                        {sales?.statusEmailReminder === 1 ? (
                                            <p className="mb-0 msz mr-2">
                                              <Mail />
                                            </p>
                                        ) : (
                                            <p className="mb-0 msz mr-2 disabled">
                                              <Mail />
                                            </p>
                                        )}
                                      </div>
                                      <div className="_right">
                                        <div className="salon_switch_container">
                                          <label
                                              className="switch"
                                              onChange={(e) =>
                                                  updateStatus(
                                                      "reviewBooster",
                                                      sales,
                                                      e?.target?.checked
                                                  )
                                              }
                                          >
                                            <input
                                                type="checkbox"
                                                checked={
                                                  sales?.statusSMSreminder === 1 ||
                                                  sales?.statusEmailReminder === 1
                                                      ? true
                                                      : false
                                                }
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                              ))
                              : null}
                        </div>
                      </div>
                      <div
                        className="_service_parent _marketting_parent _update"
                        id="_updates"
                      >
                        <h3>Updates</h3>
                        <div className="_markt_body ">
                          {marketingSetting?.bookingUpdate?.length > 0
                            ? marketingSetting?.bookingUpdate.map(
                                (booking, i) => (
                                  <div
                                    key={"booking" + booking?._id}
                                    className={"__markt"}
                                  >
                                  <div className="cursor-pointer" onClick={() =>
                                    goToUpdate('updateBooking',booking,i)
                                 }>
                                    <h6 className="mt-3">{booking?.title} </h6>
                                    <p className="">{booking?.note}</p>
                                    </div>
                                    <div className="_bottom">
                                      <div className="_left"  onClick={() =>
                                        goToUpdate('updateBooking',booking,i)
                                    }>
                                        {booking?.statusSMSreminder === 1 ? (
                                          <p className="mb-0 msz mr-2">
                                            <Msz />
                                          </p>
                                        ) : (
                                          <p className="mb-0 msz mr-2 disabled">
                                            <Msz />
                                          </p>
                                        )}
                                        {booking?.statusEmailReminder === 1 ? (
                                          <p className="mb-0 msz mr-2">
                                            <Mail />
                                          </p>
                                        ) : (
                                          <p className="mb-0 msz mr-2 disabled">
                                            <Mail />
                                          </p>
                                        )}
                                      </div>
                                      <div className="_right">
                                        <div className="salon_switch_container">
                                          <label
                                            className="switch"
                                            onChange={(e) =>
                                              updateStatus(
                                                "booking",
                                                booking,
                                                e?.target?.checked
                                              )
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              checked={
                                                booking?.statusSMSreminder ===
                                                  1 ||
                                                booking?.statusEmailReminder ===
                                                  1
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            : null}
                        </div>
                      </div>

                      <div
                        className="_service_parent _marketting_parent"
                        id="_sales"
                      >
                        <h3>Sales</h3>
                        <div className="_markt_body ">
                          {marketingSetting?.salesUpdate?.length > 0
                            ? marketingSetting?.salesUpdate.map((sales, i) => (
                                <div
                                  key={"booking" + sales?._id}
                                  className={"__markt"}
                                >
                                <div className="cursor-pointer" onClick={() =>
                                  goToUpdate('sales',sales,i)
                               }>
                                  <h6 className="mt-3">{sales?.title} </h6>
                                  {sales?.type===1?<p className="">{sales?.reminderTime} {marketingAction?.reminderTimeType(sales?.reminderTimeType
                                    )} after last booking {sales?.note}</p>:<p className=""> {sales?.note}</p>}
                                  </div>
                                  <div className="_bottom">
                                    <div className="_left"  onClick={() =>
                                      goToUpdate('sales',sales)
                                  }>
                                      {sales?.statusSMSreminder === 1 ? (
                                        <p className="mb-0 msz mr-2">
                                          <Msz />
                                        </p>
                                      ) : (
                                        <p className="mb-0 msz mr-2 disabled">
                                          <Msz />
                                        </p>
                                      )}
                                      {sales?.statusEmailReminder === 1 ? (
                                        <p className="mb-0 msz mr-2">
                                          <Mail />
                                        </p>
                                      ) : (
                                        <p className="mb-0 msz mr-2 disabled">
                                          <Mail />
                                        </p>
                                      )}
                                    </div>
                                    <div className="_right">
                                      <div className="salon_switch_container">
                                        <label
                                          className="switch"
                                          onChange={(e) =>
                                            updateStatus(
                                              "sales",
                                              sales,
                                              e?.target?.checked
                                            )
                                          }
                                        >
                                          <input
                                            type="checkbox"
                                            checked={
                                              sales?.statusSMSreminder === 1 ||
                                              sales?.statusEmailReminder === 1
                                                ? true
                                                : false
                                            }
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>

                      <div
                        className="_service_parent _marketting_parent"
                        id="_custom"
                      >
                        <h3>Custom</h3>
                        <div className="_markt_body ">
                          {marketingSetting?.customUpdate?.length > 0
                            ? marketingSetting?.customUpdate.map(
                                (custom, i) => (
                                  <div
                                    key={"booking" + custom?._id}
                                    className={"__markt"}
                                  >
                                  <div className="cursor-pointer" onClick={() =>
                                    goToUpdate('custom',custom,i)
                                 }>
                                 {console.log('check the custom reminder data',custom)}
                                    <h6 className="mt-3">{custom?.title?custom?.title:'Custom Reminder '+(i+1)} </h6>
                                    <p className="">{custom?.description
                                      ?custom?.description
                                      :null}</p>
                                   </div>
                                    <div className="_bottom">
                                      <div className="_left" onClick={() =>
                                        goToUpdate('custom',custom,i)
                                     }>
                                        {custom?.statusSMSreminder === 1 ? (
                                          <p className="mb-0 msz mr-2">
                                            <Msz />
                                          </p>
                                        ) : (
                                          <p className="mb-0 msz mr-2 disabled">
                                            <Msz />
                                          </p>
                                        )}
                                        {/* {custom?.statusEmailReminder === 1 ? (
                                          <p className="mb-0 msz mr-2">
                                            <Mail />
                                          </p>
                                        ) : (
                                          <p className="mb-0 msz mr-2 disabled">
                                            <Mail />
                                          </p>
                                        )} */}
                                      </div>
                                      <div className="_right">
                                        <div className="salon_switch_container">
                                          <label
                                            className="switch"
                                            onChange={(e) =>
                                              updateStatus(
                                                "custom",
                                                custom,
                                                e?.target?.checked
                                              )
                                            }
                                          >
                                            <input
                                              type="checkbox"
                                              checked={
                                                custom?.statusSMSreminder ===
                                                  1 ||
                                                custom?.statusEmailReminder ===
                                                  1
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <span className="slider round"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            : null}

                          <div className={"__markt _create_new"}>
                            <div className="_new cursor-pointer" onClick={()=>addReminder('custom')}>
                              <h6 className="mt-3">
                                <Addicon />
                              </h6>
                              <p className="">Create New Automation</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(Marketing);
