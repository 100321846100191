import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { Link } from 'react-router-dom';
import {hairdresserActions, paymentActions} from "../../_actions";
import { Plus,MySpinner} from '../SvgIcon';
// import Select from 'react-select';
import moment from "moment";
import {hairdresserService} from "../../_services";
import Lottie from 'react-lottie';
import animationData from '../../assets/animation-data/loader.json';
import siteSetting from "../../config/env";
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

var _ = require('lodash');

const options = [
    { value: '1', label: 'Week 1' },
    { value: '2', label: 'Week 2' },
];

const isWeekday = date => {
    const day = date.getDay(date);
    return day !== 0 &&day !== 2&&day !== 3&&day !== 4&&day !== 5&& day !== 6;
};
function AddBreak(props){
    return  <div className="break_list">
        <p>Break <i className="fa fa-times" onClick={props.removeBreak.bind(this,props.i)}/></p>
        <div className="d-flex justify-content-between">

            <div className="form-group sm_date_picker">
                <label>Start Time</label>
                <DatePicker
                    selected={props.startTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    onChange={props.selectSlot.bind(this,'breakStartTime_'+props.i)}
                />
            </div>
            <div className="form-group sm_date_picker">
                <label>Finish Time</label>
                <DatePicker
                    selected={props.endTime}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={5}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    onChange={props.selectSlot.bind(this,'breakEndTime_'+props.i)}
                />
            </div>
        </div>

    </div>
}
class Staff extends Component {
    constructor(props){
        super(props);
        this.state = {
            editHoursModal: false,
            datePickerModal: false,
            selectedDay:'',
            days:'',
            selectedHairdresser:{},
            breakTime:[],
            startTime:'',
            endTime:'',
            selectedStartDate:'',
            rotaTimeError:'',
            rotaDateError:'',
            successTimeMessage:'',
            successDateMessage:'',
            addStaffModal: false,
            activationCode:"",
            employmentTypeId:"1",
            selfEmpCount:0,
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            discount:0,
            totalPayment:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            totalPaymentBefore:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            isApplyedPromocode: false,
            applyCoupon:false,
            planPrice:siteSetting.stripe.salonMonthlyPrice,
            planType:"month",
            subscriptionInfo:{},
            oneSelfEmpPrice:siteSetting.stripe.oneSelfEmpPrice,
            oneSelfEmpPriceBefore:siteSetting.stripe.oneSelfEmpPrice,
            quantity:0,
            isRotaLoading:false,
            isRotaDeleteLoading:false,
        }

        this.onDataChange=this.onDataChange.bind(this);
        this.getslotTime=this.getslotTime.bind(this);
        this.addBreak=this.addBreak.bind(this);
        this.selectSlot=this.selectSlot.bind(this);
        this.saveRota=this.saveRota.bind(this);
        this.saveStartDateRota=this.saveStartDateRota.bind(this);
        this.deleteRota=this.deleteRota.bind(this);
        this.selectStartDate=this.selectStartDate.bind(this);
        this.removeBreak=this.removeBreak.bind(this);
        this.selectStaff=this.selectStaff.bind(this);
    }

    componentDidMount() {
        var self = this;
        if (localStorage.getItem('accessToken')) {
            let hairdresserId=localStorage.getItem('userId');
            let params = {
                searchKey:this.state.searchKey,
                'hairdresserId':hairdresserId,
                page:1,
                listType:1,
                isGetRota:1
            };
            self.props.dispatch(hairdresserActions.hairdresserStaffWithoutService(params))
            self.props.dispatch(hairdresserActions.hairdresserStaffRota(params))
        }else{
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let self = this;
        if (this.props?.staffRota?.rota !== prevProps?.staffRota?.rota) {
           if(this.props.staffRota.rota.length>0){
         let selfEmp =   this.props.staffRota.rota.filter(staff=>staff?.hairdresserInfo?.isSalonOwner==0&&staff?.hairdresserInfo?.employmentTypeId==2)
            self.setState({
                selfEmpCount:selfEmp.length,
            })
        } 
        }
    
      }


    handleEditHoursModal = (fullday,hairdresser,days) => {
        let self=this;
        if(hairdresser?.rotaInfo?.rota?.schedule) {
            let rotaTime = _.find(hairdresser?.rotaInfo?.rota?.schedule[0]?.profiles, function (obj) {
                // console.log("userService",obj.day,days);
                return obj.day === days;
            })
            //console.log("rotaTime",rotaTime);
            //console.log("rotaTime",rotaTime,this.getslotTime(rotaTime.startTime),new Date(this.getslotTime(rotaTime.startTime),"hh:MM"), moment (this.getslotTime(rotaTime.startTime),"HH:mm"));
            if(rotaTime) {
                let breakTime=[];
                _.map(rotaTime.breakTimes,function(obj,i){

                    if(obj.startTime!==''&&obj.endTime!=='') {
                        breakTime.push({id:i+1});
                        let hrBSt=parseInt(obj.startTime/60);
                        let minBSt=obj.startTime-(hrBSt*60)
                        let hrBEt=parseInt(obj.endTime/60);
                        let minBEt=obj.endTime-(hrBEt*60);
                        self.setState({
                            ['breakStartTime_'+(i+1)]:new Date().setHours(hrBSt,minBSt),
                            ['breakEndTime_'+(i+1)]:new Date().setHours(hrBEt,minBEt),
                        })
                    }
                })
                let hrSt=parseInt(rotaTime.startTime/60);
                let minSt=rotaTime.startTime-(hrSt*60)
                let hrEt=parseInt(rotaTime.endTime/60);
                let minEt=rotaTime.endTime-(hrEt*60)
                this.setState({
                    startTime: new Date().setHours(hrSt,minSt),
                    endTime: new Date().setHours(hrEt,minEt),
                    breakTime
                })
            }
        }else{
            this.setState({
                startTime:"",
                endTime:"",
                breakTime:[]
            })
        }
        
            this.setState({
                rotaTimeError:'',
                rotaDateError:'',
                successTimeMessage:'',
                successDateMessage:'',
                days:days,
                selectedDay:fullday,
                selectedHairdresser:hairdresser,
                editHoursModal: !this.state.editHoursModal
            })
        
    }


    handleDatePickerModal = (hairdresser) => {

            this.setState({
                selectedStartDate:hairdresser&&hairdresser.rotaInfo.rota&&hairdresser.rotaInfo.rota.startDate?new Date(moment(hairdresser.rotaInfo.rota.startDate,'YYYY-MM-DD')):new Date(moment().startOf('isoweek'))
            })

        this.setState({
            rotaTimeError:'',
            rotaDateError:'',
            successTimeMessage:'',
            successDateMessage:'',
            selectedHairdresser:hairdresser,
            datePickerModal: !this.state.datePickerModal
        })
    }

    getslotTime(time){
        let hr=parseInt(time/60);
        let min=time-(hr*60)
        return hr+':'+(min===0?'00':min);
    }

    getTime=(rotaInfo,days)=>{
        // console.log("rotaInfo",rotaInfo,days);
        if(rotaInfo&&rotaInfo.rota&&rotaInfo.rota.schedule){
            let rotaTime=_.find(rotaInfo.rota.schedule[0].profiles, function(obj) {
                // console.log("userService",obj.day,days);
                return obj.day=== days;
            })
            // console.log("rotaTime",rotaTime);
            if(rotaTime){
                return this.getslotTime(rotaTime.startTime)+" - "+this.getslotTime(rotaTime.endTime)
            }else{
                return <span className="text_red">Not Working</span>
            }

        }else{
            return <span className="text_red">Not Working</span>
        }
    }

    getRotaStartDate=(rotaInfo)=>{
        if(rotaInfo&&rotaInfo.rota&&rotaInfo.rota.schedule){
            return moment(rotaInfo.rota.startDate).format('ddd, DD MMM YYYY');
        }else{
            return '--'
        }
    }

    onDataChange(value, action){
        this.setState({
            [action.name]: value,
            [action.name+"Id"]:value.value
        })
    }

    addBreak(){
        let breakTime=this.state.breakTime;
        let count=breakTime.length;
        breakTime.push({id:count+1})
        this.setState({breakTime})
    }

    removeBreak(remove){
        console.log("remove",remove);
        let breakTime=this.state.breakTime;
        _.remove(breakTime, item => item.id === remove);
        this.setState({breakTime:breakTime})
    }

    selectSlot(type,e) {
        // console.log("e", e);
        // let self = this;
        this.setState({
            [type]: e
        })
    }

    getMinTime(time){
        let sTime=moment(time).format('HH:mm');
        let sTimeArr=sTime.split(":");
        console.log("sTimeArr",sTimeArr);
        let min=(parseInt(sTimeArr[0])*60)+parseInt(sTimeArr[1])
        return min;
    }

    openAddStaffmodal = ()=>{
        this.setState({
            addStaffModal: true
        })
    }
    handleClose = ()=>{
        this.setState({
            addStaffModal: false,
            activationCode:"",
            applyCoupon:false,
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            discount:0,
            employmentTypeId:"1",
        })
    }
    showActivationCode = ()=>{
        this.setState({
            applyCoupon: !this.state.applyCoupon,
        })
    }
    hideActivationCode = ()=>{
        this.setState({
            applyCoupon: false,
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            activationCode:"",
            totalPayment:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            totalPaymentBefore:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
        })
    }



    saveRota(){
        let self=this;
        let hairdresser=this.state.selectedHairdresser;
        //hairdresser.rotaInfo&&hairdresser.rotaInfo.rota&&hairdresser.rotaInfo.rota.schedule
        let profiles=[];
        if(hairdresser.rotaInfo&&hairdresser.rotaInfo.rota&&hairdresser.rotaInfo.rota.schedule) {
             _.map(hairdresser.rotaInfo.rota.schedule[0].profiles, function (obj) {
                // console.log("userService",obj.day,days);
                if(obj.day !== self.state.days){
                    profiles.push({
                        "day": obj.day,
                        "startTime": obj.startTime,
                        "endTime": obj.endTime,
                        "breakTimes": []
                    })
                }
            })
        }
        let breakTimes=[];
        _.map(self.state.breakTime,function(obj){
            console.log("obj",obj,self.state['breakStartTime_'+obj.id]!==''&&self.state['breakEndTime_'+obj.id]!=='');
            if(self.state['breakStartTime_'+obj.id]!==''&&self.state['breakEndTime_'+obj.id]!=='') {
                breakTimes.push(
                    {
                        "startTime": self.getMinTime(self.state['breakStartTime_' + obj.id]),
                        "endTime": self.getMinTime(self.state['breakEndTime_' + obj.id]),
                    }
                )
            }
        })
        profiles.push({
            "day": self.state.days,
            "startTime": self.getMinTime(self.state.startTime),
            "endTime": self.getMinTime(self.state.endTime),
            "breakTimes": breakTimes
        })
        let params = {};
        params.rotaStartDate=hairdresser.rotaInfo.startDate?hairdresser.rotaInfo.startDate:moment().startOf('isoweek').format("YYYY-MM-DD");
        params.rota=[];

        let obj={
            "weekNumber":1,
            profiles:profiles
        }
        params.rota.push(obj);
        params.hairdresserId=hairdresser._id;
        console.log("params",params);
        self.setState({
            isRotaLoading:true
        })
        hairdresserService.saveRota(params)
            .then(function (response) {
                // console.log('client response', response.data);
                if(response.data.errorCode===0){
                    self.props.dispatch(hairdresserActions.hairdresserStaffRota({isGetRota:1,'hairdresserId':localStorage.getItem('userId')})).then(res=>{
                        self.setState({
                            successTimeMessage:'Rota has been saved successfully!',
                            isRotaLoading:false
                        },function () {
                            self.handleEditHoursModal()
                        })
                    })
                 
                }else{
                    self.setState({
                        isRotaLoading:false,
                        rotaTimeError:response.data.messages
                    })
                }
            })

    }

    removerotaFromStore=()=>{
        let self=this;
        let rotaTime;

        if(this?.props?.staffRota?.rota?.length>0) {
            rotaTime = this?.props?.staffRota?.rota?.map((hairdresser, i) => {
                if(hairdresser.rotaInfo&&hairdresser.rotaInfo.rota&&hairdresser.rotaInfo.rota.schedule) {
                    let profiles=[];
                    _.map(hairdresser.rotaInfo.rota.schedule[0].profiles, function (obj) {
                        // console.log("userService",obj.day,days);
                        if(obj.day !== self.state.days){
                            profiles.push({
                                "day": obj.day,
                                "startTime": obj.startTime,
                                "endTime": obj.endTime,
                                "breakTimes": obj.breakTimes,
                                "rotaId":obj.rotaId,
                                "weekNumber":obj.weekNumber
                            })
                        }
                    })
                    hairdresser.rotaInfo.rota.schedule[0].profiles=profiles;
                    return hairdresser;
                }else{
                    return hairdresser;
                }
            })
        }
        // console.log("@@@@@",rotaTime);
        self.props.dispatch(hairdresserActions.staffRotaUpdateDelete({rota:rotaTime}));
    }

    deleteRota(){
        let self=this;
        let hairdresser=this.state.selectedHairdresser;

        //hairdresser.rotaInfo&&hairdresser.rotaInfo.rota&&hairdresser.rotaInfo.rota.schedule
        let profiles=[];
        if(hairdresser.rotaInfo&&hairdresser.rotaInfo.rota&&hairdresser.rotaInfo.rota.schedule) {
             let rotaTime = _.map(hairdresser.rotaInfo.rota.schedule[0].profiles, function (obj) {
                // console.log("userService",obj.day,days);
                if(obj.day !== self.state.days){
                    profiles.push({
                        "day": obj.day,
                        "startTime": obj.startTime,
                        "endTime": obj.endTime,
                        "breakTimes": []
                    })
                }
            })
        }
        // profiles.push({
        //     "day": self.state.days,
        //     "startTime": self.getMinTime(self.state.startTime),
        //     "endTime": self.getMinTime(self.state.endTime),
        //     "breakTimes": []
        // })
        let params = {};
        params.rotaStartDate=hairdresser.rotaInfo.startDate?hairdresser.rotaInfo.startDate:moment().startOf('isoweek').format("YYYY-MM-DD");
        params.rota=[];

        let obj={
            "weekNumber":1,
            profiles:profiles
        }
        params.rota.push(obj);
        params.hairdresserId=hairdresser._id;
        console.log("params",params);
        
        self.setState({
            isRotaDeleteLoading:true
        });

        hairdresserService.saveRota(params)
            .then(function (response) {
                // console.log('client response', response);
                if(response.data.errorCode==0){
                    self.removerotaFromStore();
                    self.setState({
                        successTimeMessage:'Rota has been saved successfully!',
                        isRotaDeleteLoading:false
                    },function () {
                        self.handleEditHoursModal()
                    })
                    self.props.dispatch(hairdresserActions.hairdresserStaffRota({isGetRota:1,'hairdresserId':localStorage.getItem('userId')})).then(res=>{
                        // self.setState({
                        //     successTimeMessage:'Rota has been saved successfully!',
                        //     isRotaDeleteLoading:false
                        // },function () {
                        //     self.handleEditHoursModal()
                        // })
                    })
                    
                }else{
                    self.setState({
                        rotaTimeError:response.data.messages,
                        isRotaDeleteLoading:false,
                    })
                }
            })
    }

    saveStartDateRota(){
        let self=this;
        let hairdresser=this.state.selectedHairdresser;
        //hairdresser.rotaInfo&&hairdresser.rotaInfo.rota&&hairdresser.rotaInfo.rota.schedule
        let profiles=[]
        if(hairdresser.rotaInfo&&hairdresser.rotaInfo.rota&&hairdresser.rotaInfo.rota.schedule) {
            let rotaTime = _.map(hairdresser.rotaInfo.rota.schedule[0].profiles, function (obj) {
                // console.log("userService",obj.day,days);
                //if(obj.day !== self.state.days){
                    profiles.push({
                        "day": obj.day,
                        "startTime": obj.startTime,
                        "endTime": obj.endTime,
                        "breakTimes": []
                    })
                //}
            })
        }

        let params = {};
        params.rotaStartDate=moment(this.state.selectedStartDate).format('YYYY-MM-DD');
        params.rota=[];

        let obj={
            "weekNumber":1,
            profiles:profiles
        }
        params.rota.push(obj);
        params.hairdresserId=hairdresser._id;
        console.log("params",params);
        hairdresserService.saveRota(params)
            .then(function (response) {
                console.log('client response', response);
                if(response.data.errorCode==0){
                    self.props.dispatch(hairdresserActions.hairdresserStaffRota({isGetRota:1,'hairdresserId':localStorage.getItem('userId')}))
                    self.setState({
                        successDateMessage:'Rota has been saved successfully!'
                    },function () {
                        self.handleDatePickerModal()
                    })
                }else{
                    self.setState({
                        rotaDateError:response.data.messages
                    })
                }
            })

    }

    selectStartDate(e){
        this.setState({
            selectedStartDate: e
        })
    }

    selectStaff(staffId,staff){
        if(staff.hairdresserInfo.isSalonOwner!==1) {
            this.props.history.push("staff/" + staffId)
        }
    }

    handleRadioChange=(event)=>{
        this.setState({
            employmentTypeId: event.target.value,
            activationCode:"",
            applyCoupon:false,
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            discount:0,
        });
    }

    addStaff=()=>{
        let self = this;
        let info={
            activationCode:self.state.activationCode,
            employmentTypeId:self.state.employmentTypeId,
            selfEmpCount:self.state.selfEmpCount,
        };
        self.props.history.push({pathname:'/staff/add',state:info})
        //this.props.history.push('/staff/add');
    }

    applyCoupan=()=>{
        this.setState({
            activatationCodeError:"",
            error:"",
            activatationCodeSuccess:"",
            totalPayment:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            totalPaymentBefore:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
        });
        let self = this;
        if(this.state.activationCode.trim()!="") {
            self.props.dispatch(paymentActions.activationCode({activationCode: self.state.activationCode.trim()}))
                .then(function (response) {
                    console.log("response", response);
                    if (response.data.statusCode == 0) {
                        if (response.data.error.errorCode === 10) {
                            self.setState({
                                activatationCodeError: 'Activation Code “'+self.state.activationCode+'” does not exist'
                            })
                        } else {
                            self.setState({
                                activatationCodeError: 'Activation Code “'+self.state.activationCode+'” does not exist'
                            })
                        }
                    } else {
                        let discount = self.state.discount, totalPayment = self.state.totalPayment;
                        if (response.data.responseData.result.percent_off) {
                            discount = (response.data.responseData.result.percent_off / 100) * totalPayment;
                            totalPayment = totalPayment - discount
                        }
                        if (response.data.responseData.result.amount_off) {
                            discount = response.data.responseData.result.amount_off/100;
                            totalPayment = totalPayment - discount
                        }
                        self.setState({
                            discount,
                            totalPayment,
                            isApplyedPromocode: true,
                            activatationCodeSuccess: "Activation code applied successfully"
                        })
                    }
                })
        }else{
            self.setState({
                activatationCodeError: "Please enter activation code",
            })
        }
    }

    onChanged=(e)=> {
        this.setState({
            [e.target.name]: e.target.value,
            activatationCodeError:"",
            error:"",
        });
        if(e.target.name==="activationCode"){
            this.setState({
                isApplyedPromocode:false,
                discount:0,
                activatationCodeSuccess:"",
                totalPayment:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
                totalPaymentBefore:siteSetting.stripe.salonMonthlyPrice+siteSetting.stripe.oneSelfEmpPrice,
            })
        }
    }

    render() {
        console.log("this.prop",this.props);
        console.log("this.state",this.state);
        let { editHoursModal, datePickerModal, addStaffModal} = this.state;
        return (
            <div className="h_n">
                 {console.log("marketibgproips---",this.props)}
            <div className="body_container cm_bg_white staff_page staff-page-temp">
                <TopHeader title="Staff" {...this.props}/>
                <SideNav {...this.props}/>
                <Tabs defaultActiveKey={this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1?"staffMember":"staffHours"}>
                    {this.props.user&&this.props.user.userInfo&&this.props.user.userInfo.hairdresser.isSalonOwner===1?
                    <Tab eventKey="staffMember" title="Staff Members" className="table_staf_member">
                        {/* <Link className="btn btn-dark top_right" onClick={()=>this.openAddStaffmodal()}>Add Staff</Link> */}
                        <Link className="btn btn-dark top_right" onClick={()=>this.addStaff()}>Add Staff</Link>
                        <div className="table-responsive  cm_table">
                            <table className="table table-borderless">
                                <thead className="cm_fw_b">
                                <tr>
                                    <td className="border-0"></td>
                                    <td className="border-0">Name</td>
                                    <td className="border-0">Employment</td>
                                    <td className="border-0 text-center">Commission</td>
                                    <td className="border-0">Email</td>
                                    <td className="border-0">Job Title</td>
                                    {/*<td className="border-0">Online Bookings</td>*/}
                                    <td className="border-0">Number</td>
                                </tr>
                                </thead>
                                <tbody>
                                {/*{ this.props.staff.total&&this.props.staff.total.length>0?*/}
                                {/*    this.props.staff.total.map((staff,i) => (*/}
                                { this.props.staffRota.rota ?(this.props.staffRota.rota.length>0?
                                    this.props.staffRota.rota.map((staff,i) => (
                                        staff && staff.name!=="Unknown"?
                                <tr key={staff._id} className={staff.hairdresserInfo.isSalonOwner!==1?"pointer-event":""} onClick={this.selectStaff.bind(this,staff._id,staff)}>
                                    <td><img src={staff.userImage.circleImage?staff.userImage.circleImage: require('../../assets/images/client-plachoder.jpg')} /></td>
                                    <td>{staff.name}</td>
                                    <td>{staff.hairdresserInfo.isSalonOwner==1?"Owner":""}{staff.hairdresserInfo.isSalonOwner==2?"Account Manager":""}{staff.hairdresserInfo.isSalonOwner==0&&staff.hairdresserInfo.employmentTypeId==1?"Employed":""}{staff.hairdresserInfo.isSalonOwner==0&&staff.hairdresserInfo.employmentTypeId==2?"Self-Employed":""}</td>
                                    <td className="text-center">{staff.hairdresserInfo.employmentTypeId==2?(staff.hairdresserInfo.salonCommissionRate&&staff.hairdresserInfo.salonCommissionRate>0?staff.hairdresserInfo.salonCommissionRate+"%":"No"):"N/A"}</td>
                                    <td>{staff.email}</td>
                                    {/*<td>Yes</td>*/}
                                    <td>{staff.hairdresserInfo.jobTitle}</td>
                                    <td>{staff.phoneNumber&&staff.phoneNumber!=''?"+"+staff.phoneNumber:"--"}</td>
                                </tr> :""))
                                :
                                <tr className="pointer-event">
                                <td colSpan={6} className="text-center">
                                    No Data Available
                                </td>
                            </tr>)
                                    :
                                    <tr>
                                    <td colSpan={6} className="text-center">
                                        <Lottie options={defaultOptions}
                                            height={50}
                                            width={200}
                                        />
                                        <h4 className="">Loading Data...</h4>

                                    </td>
                                    </tr>
                                        // <tr>
                                        //     <td><img src={require('../../assets/images/client-plachoder.jpg')} /></td>
                                        //     <td>Tess Ting</td>
                                        //     <td>Employed</td>
                                        //     <td>--</td>
                                        //     <td>--</td>
                                        //     <td>--</td>
                                        // </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                        :""}
                    <Tab eventKey="staffHours" title="Hours">
                        <div className="">
                        </div>
                        <div className="d-flex justify-content-between">
                            <h3>Staff Timesheet</h3>
                            {/*<DatePicker />*/}
                        </div>
                        <div className="table-responsive  cm_table">
                            <table className="table table-bordered">
                                <thead>
                                    <tr className="cm_fw_b">
                                        <td style={{fontSize: '24px',fontWeight: '300'}}>Staff</td>
                                        <td>Mon</td>
                                        <td>Tue</td>
                                        <td>Wed</td>
                                        <td>Thu</td>
                                        <td>Fri</td>
                                        <td>Sat</td>
                                        <td>Sun</td>
                                    </tr>
                                </thead>
                                <tbody className="pointer_c">
                                    {console.log('check the rota value',this?.props?.staffRota, this?.props?.staffRota?.rota)}
                                {this?.props?.staffRota?.rota?.length>0?
                                    this?.props?.staffRota?.rota?.map((staff,i) => (
                                        staff.name!=="Unknown"?
                                            <tr key={staff._id} className="left_bg">
                                                {/*<td><Link to="staff/1"><img src={staff.userImage.circleImage?staff.userImage.circleImage:"https://cdn5.vectorstock.com/i/thumb-large/86/39/default-placeholder-fitness-trainer-in-a-t-shirt-vector-21188639.jpg"} /></Link></td>*/}
                                                <td onClick={this.handleDatePickerModal.bind(this,staff)}>
                                                    {/* <Link to
                                                    ={"staff/"+staff._id}>{staff.name}</Link> */}
                                                    <span><b>{staff.name}</b></span>
                                                    <p className="m-0">Start Date: {this.getRotaStartDate(staff.rotaInfo)}</p>
                                                </td>
                                                {/*<td><Select*/}
                                                {/*    options={options}*/}
                                                {/*    classNamePrefix="custom"*/}
                                                {/*    name={staff._id+"_rotaType"}*/}
                                                {/*    onChange={this.onDataChange}*/}
                                                {/*    value={this.state[staff._id+"_rotaType"]}*/}
                                                {/*/></td>*/}
                                                <td><span onClick={this.handleEditHoursModal.bind(this,"Monday",staff,"mon")}>{this.getTime(staff.rotaInfo,"mon")}</span></td>
                                                <td><span onClick={this.handleEditHoursModal.bind(this,"Tuesday",staff,"tue")}>{this.getTime(staff.rotaInfo,"tue")}</span></td>
                                                <td><span onClick={this.handleEditHoursModal.bind(this,"Wednesday",staff,"wed")}>{this.getTime(staff.rotaInfo,"wed")}</span></td>
                                                <td><span onClick={this.handleEditHoursModal.bind(this,"Thursday",staff,"thu")}>{this.getTime(staff.rotaInfo,"thu")}</span></td>
                                                <td><span onClick={this.handleEditHoursModal.bind(this,"Friday",staff,"fri")}>{this.getTime(staff.rotaInfo,"fri")}</span></td>
                                                <td><span onClick={this.handleEditHoursModal.bind(this,'Saturday',staff,"sat")}>{this.getTime(staff.rotaInfo,"sat")}</span></td>
                                                <td><span onClick={this.handleEditHoursModal.bind(this,'Sunday',staff,"sun")}>{this.getTime(staff.rotaInfo,"sun")}</span></td>
                                            </tr>
                                     :"")):""}
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                </Tabs>
            </div>


            <Modal className="h_n cm_popup cm_hours_popup stff-modal-hour" show={editHoursModal} onHide={this.handleEditHoursModal.bind(this,'',{})} centered>
                <Modal.Header closeButton>
                <Modal.Title>Edit {this.state.selectedHairdresser?this.state.selectedHairdresser.name:""}’s Hours</Modal.Title>
                <p>{this.state.selectedDay}</p>
                </Modal.Header>
                <Modal.Body>

                       <div className="d-flex justify-content-between">
                           <div className="form-group sm_date_picker">
                               <label>Start Time</label>
                               <DatePicker
                                   selected={this.state.startTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onChange={this.selectSlot.bind(this,'startTime')}
                                />
                           </div>
                           <div className="form-group sm_date_picker">
                               <label>Finish Time</label>
                               <DatePicker
                                   selected={this.state.endTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onChange={this.selectSlot.bind(this,'endTime')}
                                />
                           </div>
                       </div>
                        {
                            this.state.breakTime.map((breakTime,i) => (
                                <AddBreak key={"break_"+i} selectSlot={this.selectSlot} i={breakTime.id} startTime={this.state['breakStartTime_'+breakTime.id]} endTime={this.state['breakEndTime_'+breakTime.id]} removeBreak={this.removeBreak}/>
                            ))
                        }
                        <div className="add_break_btn" onClick={this.addBreak.bind(this)}>
                        <Plus /> Add break
                        </div>


                        {/*<div className="form-group">*/}
                        {/*    <label>Repeats</label>*/}
                        {/*    <select className="form-control">*/}
                        {/*        <option>Weekly</option>*/}
                        {/*        <option>Daily</option>*/}
                        {/*        <option>Monthly</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}

                        <div className="mt-4 mb-4 text-center">
                            <button className="btn btn-pink pl-4 pr-4" onClick={this.deleteRota.bind(this)}>{this.state.isRotaDeleteLoading?<MySpinner/>:'Delete'}</button>
                            <button onClick={this.handleEditHoursModal.bind(this,'',{})} className="btn btn-outline-dark pl-4 pr-4 ml-3">Cancel</button>
                            <button type="button" className="btn btn-dark ml-3 pl-4 pr-4" onClick={this.saveRota.bind(this)}>&nbsp;{this.state.isRotaLoading?<MySpinner/>:'Save'} </button>
                        </div>
                    <br/>
                    {this.state.successTimeMessage ?
                        <span className="cm_succ_msg">{this.state.successTimeMessage}</span> : ''}
                    {this.state.rotaTimeError ?
                        <span className="cm_error_msg">{this.state.rotaTimeError}</span> : ''}
                </Modal.Body>
            </Modal>


            <Modal className="h_n cm_popup cm_hours_popup stff-modal-hour" show={datePickerModal} onHide={this.handleDatePickerModal.bind(this,'')} centered>
                <Modal.Header closeButton>
                <Modal.Title>{this.state.selectedHairdresser?this.state.selectedHairdresser.name:""}’s Start Date</Modal.Title>
                {/*<p>Saturday</p>*/}
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                            <DatePicker
                                inline
                                filterDate={isWeekday}
                                selected={this.state.selectedStartDate}
                                onChange={this.selectStartDate.bind(this)}
                            />
                            <div className="mt-4 mb-4">
                                <button onClick={this.handleDatePickerModal.bind(this,'')} className="btn btn-outline-dark pl-4 pr-4">Cancel</button>
                                <button type="button" className="btn btn-dark ml-3 pl-4 pr-4"onClick={this.saveStartDateRota.bind(this)}>Save</button>
                            </div>
                        <br/>
                        {this.state.successDateMessage ?
                            <span className="cm_succ_msg">{this.state.successDateMessage}</span> : ''}
                        {this.state.rotaDateError ?
                            <span className="cm_error_msg">{this.state.rotaDateError}</span> : ''}
                    </div>
                </Modal.Body>
            </Modal>

        </div>
        )
    }
}



function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(Staff);





