import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tab, Tabs, Dropdown, Modal } from 'react-bootstrap';
import SideNav from '../template/SideNav';
import TopHeader from '../template/TopHeader';
import { connect } from "react-redux";
import {bookingActions, hairdresserActions, paymentActions, userActions} from "../../_actions";
import siteSetting from "../../config/env";

import dateFormat from "dateformat";
import toast from 'react-toast-notification';

import animationData from '../../assets/animation-data/loader.json';
import moment2 from "moment-timezone";
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

var _ = require('lodash');
const isMobile = (window.innerWidth <= 768);
let userIdHolder = [];
const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];



function debounce(func, delay) {
    let timer;
    return function() {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  }


class NewEvent extends Component {

    constructor(props) {
        super(props);
        let bookingDate = this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):new Date();
        let bookingdate1=this.props.location.state&&this.props.location.state.bookingDate?this.props.location.state.bookingDate:new Date();
        var seconds = bookingDate.getSeconds();
        var minutes = bookingDate.getMinutes();
        var hour = bookingDate.getHours();
        var getMilliseconds =bookingDate.getMilliseconds()
        //var getTimezone = bookingDate.toString().match(/([A-Z]+[\+-][0-9]+)/)[1];
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const targetTimeZone = timeZone;
        const currentDateInTimeZone = moment2.tz(bookingdate1, targetTimeZone);
        let formattedDate = new Date(currentDateInTimeZone);
        formattedDate.setHours(hour);
        formattedDate.setMinutes(minutes);
        formattedDate.setSeconds(seconds);
        formattedDate.setMilliseconds(getMilliseconds);
        console.log('checkbookingDate',bookingDate,"=====",formattedDate);
// console.log("moment(this.props.location.state.bookingDate, 'yyyy-mm-dd')",new Date(this.props.location.state.bookingDate),this.props.location.state.bookingDate)
        this.state = {
            //bookingDate: this.props.location.state&&this.props.location.state.bookingDate?new Date(this.props.location.state.bookingDate):new Date(),
            bookingDate:formattedDate,
            selectSlot: this.props.location.state?this.props.location.state.start:'',
            showOptionsModal: false,
            isSelected: false,
            showTipModal: false,
            latitude: siteSetting.default_latitude,
            longitude: siteSetting.default_longitude,
            hairdresserName: 'Select Staff Member',
            selectedService: [],
            finalBookingSlots: [],
            includeTimes: [],
            slotInfo: {},
            totalAmount: 0,
            totalTime: 0,
            hairdresserId: this.props.match.params.hairdresserId?this.props.match.params.hairdresserId:'',
            selectedClientId: '',
            selectedClient: {},
            isSaveBooking: 0,
            bookingSlots: [],
            paymentType: '',
            isBookingAllowed: false,
            searchKey: '',
            selectTab:1,
            pendingAmount:0,
            transaction:[],
            paymentAmount:0,
            totalPaymentAmount:0,
            isInvoice:0,
            eventError:'',
            startTimeError:'',
            serviceError:'',
            bookingError:'',
            eventStartTime:0,
            eventEndTime:0,
            eventTitle:'',
            eventTitleError:'',
            hairdresserIdEventError:'',
            currencySymbol:"£",
            isSelectClient:false,
            mobileSelectedStep:0,
            selectedPaymentType:null,
            selectedAmount:'',
            checkOutAmountType:true,
            selectedFixedAmount:0,
            isPaymentComplete:0,
            bookingID:'',
            isMobileEnableState:true,
            locationId:'',
            selectedCardReader:{}
        }
        this.changeHairdresser = this.changeHairdresser.bind(this);
        this.selectDay = this.selectDay.bind(this);
    }

    componentDidMount() {
        var self = this;
        let params = {
            defaultRedirectTo:localStorage.getItem('defaultRedirectTo')
        };
        let params1 = {
            searchKey:this.state.searchKey,
            'hairdresserId':localStorage.getItem('userId'),
            page:1,
            listType:1
        };
        if (localStorage.getItem('accessToken')) {
            self.props.dispatch(userActions.profile(params))
            .then(function (response) {
                //console.log("response",response.data.data);
                if(response.data.data) {
                    self.setState({
                        locationId:response.data.data.userInfo.hairdresser.stripeLocationId?response.data.data.userInfo.hairdresser.stripeLocationId:""
                    })
                }
            })

            self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': localStorage.getItem('userId'), page: 1, listType: 1 }))
            self.props.dispatch(hairdresserActions.hairdresserStaffRota(params1))
                .then(function (response) {
                    // console.log("response",response);
                    // response.data.data.rota.map((hairdresser,i)=>{
                    //     self.getHairdresserScheduleList(hairdresser._id,hairdresser,i);
                    // })
                    if(self.props.match.params.hairdresserId){
                        let selectedHairdresser = _.find(response.data.data.rota, function (hairdresser) {
                            return hairdresser._id === self.props.match.params.hairdresserId;
                        })
                        self.setState({
                            hairdresserName:selectedHairdresser.name
                        })
                        self.changeHairdresser(self.props.match.params.hairdresserId,selectedHairdresser.name,1,selectedHairdresser);
                        self.selectDay(self.state.bookingDate);
                    }
                })

                // console.log("self props",self.props);
                // console.log("self state",self.state);
            
        } else {
            this.props.history.push('/')
        }

        let { dispatch } = this.props;
        //navigator.geolocation.getCurrentPosition(this.foundLocation, this.noLocation.bind(this))
    }


    handleEvent(e, d) {
        console.log('check the event time picker id',e, d);
        if (e === "eventStartTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ eventTime: d, eventStartTime: minutes })
        }
        if (e === "eventEndTime") {
            let m = moment(d);
            let minutes = (m.hour() * 60) + m.minute();
            this.setState({ endTime: d, eventEndTime: minutes })
        }
        if (typeof e === "object" && e.target.id === "eventTitle") {
            this.setState({ eventTitle: e.target.value })
        }
        if (typeof e === "object" && e.target.id === "isBookingAllowed") {
            this.setState({ isBookingAllowed: !this.state.isBookingAllowed })
        }
    }

    submitEvent = () => {
        this.setState({
            eventStartTimeError:'',
            eventEndTimeError:'',
            eventTitleError:'',
            hairdresserIdEventError:''
        })
        let self = this;
        let params = {
            'eventTitle': self.state.eventTitle,
            'eventDate': dateFormat(self.state.bookingDate, 'yyyy-mm-dd'),
            'eventStartTime': self.state.eventStartTime,
            'eventEndTime': self.state.eventEndTime,
            'isBookingAllowed': self.state.isBookingAllowed ? 1 : 0,
            'userId': self.state.hairdresserId,
            'deviceToken': "dfdsfdsfsd",
            'repeatType': "doesNotRepeat"   //'doesNotRepeat', 'everyDay', 'everyWeek', 'everyMonth', 'everyYear'
        }
        if(self.state.eventStartTime===''||self.state.eventStartTime===0){
            this.setState({
                eventStartTimeError:'Please select start time'
            })
        }else if(self.state.eventEndTime===0||self.state.eventEndTime===''){
            this.setState({
                eventEndTimeError:'Please select end time'
            })
        }else if(self.state.eventTitle===''){
            this.setState({
                eventTitleError:'Please enter event title'
            })
        }else if(self.state.hairdresserId===''){
            this.setState({
                hairdresserIdEventError:'Please select any hairdresser'
            })
        }else {
            self.props.dispatch(bookingActions.createEvent(params))
                .then(function (response) {
                    console.log('response', response);
                    if (response.data.errorCode) {
                        self.setState({
                            eventError: response.data.messages
                        })
                    } else {
                        let info={
                            bookingDate:self.state.bookingDate
                        };
                        self.props.history.push({pathname:'/diary/' + localStorage.getItem('salonId'),state:info})
                        //self.props.history.push('/diary/' + localStorage.getItem('salonId'))
                        //window.location.reload();
                    }
                })
        }
    }


    changeHairdresser(hairdresserId, hairdresserName,type,hairdresser) {
        this.setState({isLoaderEnabled: true})
        this.setState({isSelected: true})
        this.setState({ startTimeError:'',serviceError:'',hairdresserId, hairdresserName })
        // console.log("salonId",salonId);
        //this.props.history.push("/salon/" + salonId + "/professional/" + hairdresserId);
        let params = {};
        let self = this;
        params.hairdresserId = hairdresserId;
        params.latitude = this.state.latitude;
        params.longitude = this.state.longitude;
        // params.history = this.props.history;
        params.slotsForBookingDate=dateFormat(this.state.bookingDate?this.state.bookingDate:new Date(),"yyyy-mm-dd")
        if(type===1){
            //self.props.dispatch(hairdresserActions.getHairdresserClient({ history: this.props.history, 'searchKey': this.state.searchKey, 'hairdresserId': hairdresserId, page: 1, listType: 1 }))
        }

        self.props.dispatch(hairdresserActions.getHairdresserInfo(params))
            .then(function (response) {

                if (response.data.errorCode) {
                    self.setState({
                        isLoaderEnabled: false,
                        error: response.data.error.messages
                    })
                } else {
                    self.setState({
                        isLoaderEnabled: false,
                        bookingSlots: response.data.data.bookingSlots,
                        date: new Date(),
                        selectedService: [],
                        isBookingButton: false,
                        selectedDay: new Date(),
                        month: monthNames[new Date().getMonth()],
                        year: new Date().getFullYear(),
                        totalAmount: 0,
                        totalTime: 0,
                        selectedBookingSlots: '',
                        selectedBookingSlotsIndex: '',
                        finalBookingSlots: [],
                        includeTimes: [],
                        pendingAmount:0,
                        transaction:[],
                        paymentAmount:0,
                        totalPaymentAmount:0,
                        currencySymbol:hairdresser.hairdresserInfo.currencySymbol&&hairdresser.hairdresserInfo.currencySymbol!=""&&hairdresser.hairdresserInfo.currencySymbol!="undefined"?hairdresser.hairdresserInfo.currencySymbol:"£"
                    }, function () {
                        //self.bookingSlotCreate(0);
                    })
                }
            });
    }

    selectDay(e) {
        this.setState({
            startTimeError:'',
            serviceError:''
        })
        let self = this;
        let sdate = new Date(e[0])
        self.setState({
            bookingDate: e,
            month: monthNames[sdate.getMonth()],
            year: sdate.getFullYear()
        })
    }

   navigateTabs = (navName) =>{
        let self = this;
        let currentPageUrl = self.props.history.location.pathname;
        console.log('check navigation tab 2',currentPageUrl);
        const stateObj = {...self.props.location.state, pageLoadingStatus: 1, previousPageUrl:currentPageUrl};
        const url =  `/${navName}/` + self.props.match.params.salonId;
         self.props.history.push({
          pathname: url,
          state: stateObj,
        });
      }

    render() {
        let { bookingDate, showOptionsModal, showTipModal, hairdresserName } = this.state;
        let { hairdresser, salon } = this.props;

        let displayData = [];

        return (
            <div className="h_n">

                {/* <div className="body_container cm_bg_white"> */}
                <div className="body_container body_bg_lightGray">
                    
                        <TopHeader title="Event"
                         headerButtonStatus={true}
                         cancelButton={true}
                         {...this.props}
                          />
                        <SideNav {...this.props} />
                        <nav className="nav nav-tabs _newCheckout_wrapper navbar__space">
                            <span
                                className="nav-item nav-link "
                                onClick={()=>this.navigateTabs('newBooking')}
                            >
                                Booking
                            </span>
                            <span
                                className="nav-item nav-link active"
                                onClick={()=>this.navigateTabs('event')}
                            >
                                Event
                            </span>
                            {/* <span className="nav-item nav-link"
                             onClick={()=>this.navigateTabs('new-checkout')}
                           >
                                Checkout
                            </span> */}
                        </nav>
                        <div className="booking-parent booking_space__fix">

                                <div className="tab_content_wrapper">


                                <div className="cm_picker_wrap" htmlFor="">
                                <p>{moment(bookingDate).format('dddd DD MMM YYYY')} <i className="fa fa-angle-down" /></p>
                                <DatePicker
                                selected={this.state.bookingDate}
                                minDate={new Date()}
                                onChange={this.selectDay.bind(this)}
                                pre
                                className="__datepicker"
                                />
                                </div>
                                <div className="booking_form_wrapper bg-white">

                                <div className="cm_row m-0">
                                <div className="cm_25">
                                <div className="form-group">

                                <label>Start Time</label>
                                <div className="cm_picker_wrap pad-0 __event">
                                <input type="text" value={this.state.eventTime ?moment (this.state.eventTime).format('HH:mm') : ""} className="form-control" placeholder="00:00" />
                                <DatePicker
                                selected={this.state.eventTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onChange={this.handleEvent.bind(this, 'eventStartTime')}
                                />
                            {this.state.eventStartTimeError ?
                                <span className="cm_error_msg">{this.state.eventStartTimeError}</span> : ''}
                                </div>
                                </div>
                                <div className="form-group">
                                <label>Finish Time</label>
                                <div className="cm_picker_wrap pad-0">
                                <input type="text" value={this.state.endTime ? moment(this.state.endTime).format('HH:mm') : ""} className="form-control" placeholder="00:00" />
                                <DatePicker
                                selected={this.state.endTime}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={5}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                                timeFormat="HH:mm"
                                onChange={this.handleEvent.bind(this, 'eventEndTime')}
                                />
                            {this.state.eventEndTimeError ?
                                <span className="cm_error_msg">{this.state.eventEndTimeError}</span> : ''}
                                </div>
                                </div>

                                </div>
                                <div className="cm_75">
                                <div className="form-group">
                                <label>Event Title</label>

                                <input type="text" id="eventTitle" value={this.state.eventTitle} onChange={this.handleEvent.bind(this)} placeholder="Event Title" className="form-control" />
                            {this.state.eventTitleError ?
                                <span className="cm_error_msg">{this.state.eventTitleError}</span> : ''}
                                </div>
                                <div className="form-group mt-38">
                                <label>Staff Member</label>

                                <Dropdown className="cm_select_wrapper">
                                <Dropdown.Toggle className="cm_select" id="dropdown-basic" >{hairdresserName}</Dropdown.Toggle>

                                <Dropdown.Menu>
                            {this.props.staffRota.rota && this.props.staffRota.rota.length>0 ? (
                                <ul className="cm_select_ul">
                            {this.props.staffRota.rota.map((hairdresser, i) => (
                                hairdresser.name !== 'Unknown' && hairdresser.email !== 'Unknown' ?
                                (<li key={hairdresser._id} onClick={this.changeHairdresser.bind(this, hairdresser._id, hairdresser.name,1,hairdresser)}><Dropdown.Item>{hairdresser.name}</Dropdown.Item></li>)
                                : ""))}

                                </ul>
                                ) : ""}
                                </Dropdown.Menu>
                                </Dropdown>
                            {this.state.hairdresserIdEventError ?
                                <span className="cm_error_msg">{this.state.hairdresserIdEventError}</span> : ''}
                                </div>

                                </div>
                                </div>

                            {/* <div className="cm_row">
                                                    <div className="cm_25">
                                                    </div>
                                                    <div className="cm_75">
                                                         </div>
                                                </div> */}

                            {this.state.eventError ?
                                <span className="cm_error_msg">{this.state.eventError}</span> : ''}


                                </div>

                                <div className="text-right cm_right_check">
                                <div>Still available for booking
                                    <div className="check_wrap">
                                <input type="checkbox" onChange={this.handleEvent.bind(this)} id="isBookingAllowed" checked={this.state.isBookingAllowed} />
                                <span />
                                </div></div>

                                <button onClick={this.submitEvent} className="btn btn-dark">Add Event</button>
                                </div>
                                </div>
                        </div>
                    </div>
            </div>
        )
    }

}

function mapStateToProps(state) {
    return {
        ...state
    };
}

export default connect(mapStateToProps)(NewEvent);

